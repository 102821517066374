import { generateHash } from '../helpers/common';

const HASH_ITEM_NAME = 'userHash';

interface UseHash {
  initHash: () => void;
  getUserHash: () => string;
}

export const useHash = (): UseHash => {
  const initHash = () => {
    const hash = localStorage.getItem(HASH_ITEM_NAME);

    !hash && localStorage.setItem(HASH_ITEM_NAME, generateHash());
  };

  const getUserHash = () => {
    const hash = localStorage.getItem(HASH_ITEM_NAME);

    if (hash) {
      return hash;
    }

    const newHash = generateHash();
    localStorage.setItem(HASH_ITEM_NAME, newHash);

    return newHash;
  };

  return { initHash, getUserHash };
};
