import { createContext } from 'react';
import { Sort, SortType } from '../types/common';

interface SortingContextType {
  sort: Sort;
  setSpecialitySort?: (value: SortType) => void;
  setScoreSort: (value: SortType) => void;
}

export const SortingContext = createContext<SortingContextType>({
  sort: { speciality: null, score: null },
  setSpecialitySort: (value: SortType): void => {},
  setScoreSort: (value: SortType): void => {},
});
