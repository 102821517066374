import React, { FC } from 'react';
import { composeClasses as cc } from '../../../helpers/common';
import { PROFESSIONS_ROUTE } from '../../../constants/routes';

interface ProfessionsNavButtonMobileProps {
  toggleOpen: () => void;
  isOpen: boolean;
  title: string;
  activePage: string;
}

const ProfessionsNavButtonMobile: FC<ProfessionsNavButtonMobileProps> = ({
  toggleOpen,
  isOpen,
  activePage,
  title,
}) => (
  <div
    className={cc('menu-link menu-link-professions mobile expandable', {
      active: activePage === PROFESSIONS_ROUTE,
      'is-open': isOpen,
    })}
    onClick={toggleOpen}
  >
    {title}
  </div>
);
export default ProfessionsNavButtonMobile;
