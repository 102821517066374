import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HeaderSubNav from '../HeaderSubNav';
import ProfessionsNavButton from '../../professions/ProfessionsNavButton';
import { useHash } from '../../../hooks/useHash';
import { composeClasses as cc } from '../../../helpers/common';
import { setActiveCardIdAndDetailsStatus } from '../../../helpers/professions';
import {
  setActiveProfessionId,
  setProfessionShowStatus,
} from '../../../store/professionsSlice';
import { ON_HOVER_PROFESSIONS } from '../../../constants/endpoints';
import {
  ABOUT_US_ROUTE,
  CALCULATOR_DEFAULT_ROUTE,
  CALCULATOR_ROUTES,
  PROFESSIONS_ROUTE,
  START_TEST_ROUTE,
  TEST_RESULT_ROUTE,
  TEST_ROUTE,
} from '../../../constants/routes';
import { DEFAULT_ACTIVE_CARD_ID } from '../../../constants/localStorage';
import { ON_CLICK_LOGO } from '../../../constants/events';
import ProfessionsNavButtonMobile from '../../professions/ProfessionsNavButtonMobile';
import { useInitialDataLoaded } from '../../../hooks/useInitialDataLoaded';

const professionsItemTitle = 'Професії';

export const Menu: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isBrowser = typeof window !== 'undefined';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isProfessionsOpen, setProfessionsOpen] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<string>(PROFESSIONS_ROUTE);

  const dispatch = useDispatch();

  const { initHash } = useHash();

  useEffect(() => {
    setActivePage(pathname);
    initHash();
  }, [pathname]);

  useEffect(() => {
    initHash();

    setActiveCardIdAndDetailsStatus(DEFAULT_ACTIVE_CARD_ID, 'false');
    dispatch(setActiveProfessionId(Number(DEFAULT_ACTIVE_CARD_ID)));
    dispatch(setProfessionShowStatus(false));
  }, []);

  useEffect(() => {
    const closeMobileMenu = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener(ON_CLICK_LOGO, closeMobileMenu);

    return () => window.removeEventListener(ON_CLICK_LOGO, closeMobileMenu);
  }, [isOpen]);

  const toggleMenu = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const toggleProfessionsOpen = useCallback(() => {
    setProfessionsOpen((prevState) => !prevState);
  }, []);

  const setDefaultActiveCard = () => {
    setActiveCardIdAndDetailsStatus(DEFAULT_ACTIVE_CARD_ID, 'false');
    dispatch(setActiveProfessionId(Number(DEFAULT_ACTIVE_CARD_ID)));
    dispatch(setProfessionShowStatus(false));
    setIsOpen(false);
  };

  const openActiveCardForLink = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (
      window.matchMedia('(max-width: 768px)').matches
      || pathname !== PROFESSIONS_ROUTE
    ) {
      setDefaultActiveCard();
      navigate(PROFESSIONS_ROUTE);
    }
  };

  const openActiveCardForScroll = () => {
    if (
      window.matchMedia('(max-width: 768px)').matches
      || pathname !== PROFESSIONS_ROUTE
    ) {
      setDefaultActiveCard();
      navigate(PROFESSIONS_ROUTE);
    }
  };

  const onHoverProfessions = () => {
    window.dispatchEvent(new Event(ON_HOVER_PROFESSIONS));
  };

  const isInitialDataLoaded = useInitialDataLoaded();

  return isInitialDataLoaded ? (
    <div className={cc('menu-wrapper', { 'menu-open': isOpen })}>
      <nav className="menu">
        <ul className="menu-list">
          <li className="menu-item">
            <ProfessionsNavButton
              title={professionsItemTitle}
              activePage={activePage}
              openActiveCardForLink={openActiveCardForLink}
              openActiveCardForScroll={openActiveCardForScroll}
              onHoverProfessions={onHoverProfessions}
            />
            <ProfessionsNavButtonMobile
              isOpen={isProfessionsOpen}
              toggleOpen={toggleProfessionsOpen}
              title={professionsItemTitle}
              activePage={activePage}
            />
            <div
              className={cc('submenu-holder', { 'is-open': isProfessionsOpen })}
            >
              <HeaderSubNav
                onSelect={toggleMenu}
                classNameMenuWrapper="submenu"
                classNameMenuList="submenu-list"
                classNameMenuItem="submenu-item"
              />
            </div>
          </li>
          <li className="menu-item">
            <Link
              className={cc('menu-link', {
                active: CALCULATOR_ROUTES.includes(activePage),
              })}
              to={CALCULATOR_DEFAULT_ROUTE}
              onClick={setDefaultActiveCard}
            >
              Калькулятор
            </Link>
          </li>
          <li className="menu-item">
            <Link
              className={cc('menu-link', {
                active:
                  activePage === START_TEST_ROUTE
                  || activePage
                    === (isBrowser
                      && localStorage.testResult
                      && TEST_RESULT_ROUTE)
                  || activePage === TEST_ROUTE,
              })}
              to={START_TEST_ROUTE}
              onClick={setDefaultActiveCard}
            >
              Тест
            </Link>
          </li>
          <li className="menu-item">
            <Link
              className={cc('menu-link', {
                active: activePage === ABOUT_US_ROUTE,
              })}
              to={ABOUT_US_ROUTE}
              onClick={setDefaultActiveCard}
            >
              Про нас
            </Link>
          </li>
        </ul>
        <button className="menu-bar" onClick={toggleMenu}>
          <div className="menu-icon" id="menu-icon">
            <div className="bar bar1" />
            <div className="bar bar2" />
          </div>
        </button>
      </nav>
    </div>
  ) : null;
};
