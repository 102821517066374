import * as Yup from 'yup';
import {
  getMaxLengthSearchedWordMessage,
  getMinLengthSearchedWordMessage,
} from '../helpers/validationHelpers';

export const searchValidationScheme = Yup.object({
  search: Yup.string()
    .min(3, getMinLengthSearchedWordMessage)
    .max(150, getMaxLengthSearchedWordMessage),
});
