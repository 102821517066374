export const CALCULATOR_ROUTES = [
  '/calculator',
  '/calculator/school-graduate',
  '/calculator/college-graduate',
  '/calculator/school-graduate/budget',
  '/calculator/school-graduate/contract',
  '/calculator/college-graduate/budget',
  '/calculator/college-graduate/contract',
  '/calculator/college-graduate/budget/prop/:propositionId',
  '/calculator/college-graduate/contract/prop/:propositionId',
  '/calculator/school-graduate/budget/prop/:propositionId',
  '/calculator/school-graduate/contract/prop/:propositionId',
];

export const PROFESSIONS_ROUTE = '/';
export const CALCULATOR_DEFAULT_ROUTE = '/calculator';

export const TEST_ROUTE = '/test';
export const START_TEST_ROUTE = '/start-test';
export const TEST_RESULT_ROUTE = '/test-result';
export const ABOUT_US_ROUTE = '/about';

export const COURSES_LINK = 'https://nixstech.com/education/';
