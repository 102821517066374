export const CALCULATORS_PAGE_URL = `${process.env.REACT_APP_API_URL}/calculators/`;
export const CALCULATOR_URL = `${process.env.REACT_APP_API_URL}/calculators/calculate/`;
export const MOTIVATION_LETTER_URL = `${process.env.REACT_APP_API_URL}/motivation/letter/`;
export const USER_ACTION_URL = `${process.env.REACT_APP_API_URL}/applicants/action/`;
export const ABOUT_US_URL = `${process.env.REACT_APP_API_URL}/about-us/`;
export const PROF_ORIENTATION_TEST_URL = `${process.env.REACT_APP_API_URL}/proforientation-test/`;
export const PROF_ORIENTATION_TEST_ANSWERS_URL = `${process.env.REACT_APP_API_URL}/proforientation-test/answers/`;
export const EMAIL_URL = `${process.env.REACT_APP_API_URL}/proforientation-test/send-results/`;
export const PROFESSION_MAP_URL = `${process.env.REACT_APP_API_URL}/profession-map/`;
export const PROFESSION_PROFESSIONS_URL = `${process.env.REACT_APP_API_URL}/propositions/`;
export const FOOTER_URL = `${process.env.REACT_APP_API_URL}/footer/`;
