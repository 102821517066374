import { FC } from 'react';
import { Form, Formik } from 'formik';
import Button from '../Button';
import { ButtonType } from '../../../types/common';
import { searchValidationScheme } from '../../../schemas/searchSchema';
import { SearchField } from '../SearchField/SearchField';

interface SearchProps {
  onSubmit: (value: string) => void;
}

interface FormikValue {
  search: string;
}

export const Search: FC<SearchProps> = ({ onSubmit }) => {
  const initialValue: FormikValue = {
    search: '',
  };

  const handleSubmit = (searchedPropositions: FormikValue) => {
    onSubmit(searchedPropositions.search);
    (document.activeElement as HTMLInputElement).blur();
  };

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={searchValidationScheme}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form className="search-form" action="">
          <div className="search-wrapper">
            <Button className="button-search" type={ButtonType.SUBMIT}>
              <span className="search-icon" />
            </Button>
            <SearchField />
          </div>
        </Form>
      )}
    </Formik>
  );
};
