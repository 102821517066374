import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import {
  FormControl, Input, MenuItem, SelectChangeEvent,
} from '@mui/material';
import { ValidationErrors } from '../ValidationErrors/ValidationErrors';
import { SelectCourses } from '../../../types/common';

interface CourseSelectProps {
  selectName: string;
  selectTitle: string;
  selectItems: SelectCourses[];
  selectedValue: string;
  onChange?: (event: SelectChangeEvent<string>) => void;
}

export const CourseSelect: FC<CourseSelectProps> = ({
  selectName,
  selectTitle,
  selectItems,
  selectedValue,
  onChange,
}) => (
  <FormControl className="select-holder" fullWidth>
    <InputLabel className="inputLabel" shrink={false}>
      {selectTitle}
    </InputLabel>
    <Field name={selectName}>
      {(props: FieldProps) => (
        <div className="select-content">
          <Select
            input={<Input />}
            name="select"
            className={`subject-select ${
              props.meta.error && props.meta.touched ? 'is-invalid' : ''
            } ${selectedValue ? 'has-value' : ''}`}
            value={selectedValue}
            onChange={onChange}
            {...props}
            inputProps={{ IconComponent: () => null }}
          >
            <MenuItem
              className="subject-option"
              value={null as unknown as string}
              key="empty"
            >
              Відсутній
            </MenuItem>
            {selectItems
              && selectItems.map((select) => (
                <MenuItem
                  className="subject-option"
                  value={select.university}
                  key={select.id}
                >
                  {select.code ? `${select.code}\u00A0\u00A0\u00A0` : null}
                  {select.localization.ua.title}
                </MenuItem>
              ))}
          </Select>
          {props.meta.touched && props.meta.error && (
            <ValidationErrors
              errorId={`errors-${selectName}`}
              errorContent={props.meta.error}
            />
          )}
        </div>
      )}
    </Field>
  </FormControl>
);
