export enum ButtonType {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum InputType {
  NUMBER = 'number',
  TEXT = 'text',
  SEARCH = 'search',
}

export interface AboutItemProps {
  id: number;
  name: string;
  url: string;
  localization: {
    title: string;
    content: string;
  }[];
}

export type SubjectResponse = {
  id: number;
  alias: string;
  level: string;
  localization: Record<
    string,
    {
      title: string;
    }
  >;
};

export interface StagesInLang {
  about: string;
  timing: string;
}

export interface Stage {
  en: StagesInLang;
  startsAt: string;
  endsAt: string;
  id: number;
  stageNumber: number;
  localization: Record<string, StagesInLang>;
}

export type PromptsData = {
  calculator: {
    localization: Record<
      string,
      {
        description: string;
      }
    >;
  };
  minScore: {
    localization: Record<
      string,
      {
        description: string;
      }
    >;
  };
  restriction: {
    localization: Record<
      string,
      {
        description: string;
      }
    >;
  };
};

export type Calculators = {
  school: {
    subjects: [SubjectResponse];
    courses: [SelectCourses];
  };
  college: {
    specialities: [SelectSpecialities];
  };
};

export type MainPageResponseData = {
  calculators: Calculators;
  hints: PromptsData;
  letter: string;
  propositions: PropositionsData;
  sections: AboutItemProps[];
  stages: Stage[];
};

enum SelectHeaderType {
  COURSE = 'course',
  SPECIALITY = 'speciality',
}

export interface SelectHeader {
  id: number;
  type: SelectHeaderType;
  localization: Record<
    string,
    {
      title: string;
    }
  >;
  code?: string;
  university?: string;
}

export interface SelectCourses extends SelectHeader {
  type: SelectHeaderType.COURSE;
  university: string;
}

export interface SelectSpecialities extends SelectHeader {
  type: SelectHeaderType.SPECIALITY;
  code: string;
}

export interface SelectSubjects {
  id: number;
  type: string;
  localization: Record<string, { title: string }>;
}

export interface SchoolFormContent {
  ukr: string | number;
  mat: string | number;
  his: string | number;
  subject: Record<string, string | number>;
  course: Record<string, string | number | null>;
  status: boolean;
}

export type CollegeFormContent = Omit<SchoolFormContent, 'course'>;

export interface Speciality {
  code: string;
  alias: string;
  localization: Record<
    string,
    {
      title: string;
      alias: string;
    }
  >;
}

export interface PropositionFullData {
  id: number;
  govOrder: number;
  contractPrice: number;
  detailsLink: string;
  contactPhone: string;
  passScore: number;
  enrolleeScore?: number;
  nixPartnership: boolean;
  speciality: Speciality;
  department: {
    telegram: string;
    webSite: string;
    instagram: string;
    youtube: string;
    facebook: string;
    localization: Record<
      string,
      {
        title: string;
      }
    >;
    university: {
      webSite: string;
      alias: string;
      localization: Record<
        string,
        {
          title: string;
        }
      >;
    };
  };
  localization: Record<
    string,
    {
      title: string;
      description: string;
      studyPeriod: string;
    }
  >;
}

export interface ResponsePropositions {
  propositions: PropositionsData;
}

export interface PropositionsData {
  budget: PropositionFullData[];
  contract: PropositionFullData[];
}

export interface ShortPropositionData {
  id: number;
  nixPartnership: boolean;
  ua: {
    title: string;
  };
  speciality?: {
    alias: string;
    localization: Record<
      string,
      {
        title: string;
        alias: string;
      }
    >;
  };
  department: {
    university: {
      localization: Record<
        string,
        {
          title: string;
        }
      >;
    };
  };
  passScore: number | undefined;
  enrolleeScore: number | undefined;
}

export interface Filters {
  universitiesFilter: string[];
  selectionsFilter: boolean;
  partnersFilter: boolean;
}

export enum SortType {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Sort {
  speciality: SortType | null;
  score: SortType | null;
}

export enum Action {
  LIKED = 'liked',
  LOOKED = 'looked',
}

export interface ActionData {
  propositionId: number;
  hash: string;
  action: Action;
}

export enum Navigation {
  CARD_DETAILS = 'card-details',
}

export enum LayoutTabulation {
  BUDGET = 'budget',
  CONTRACT = 'contract',
}

export enum HeaderTabulation {
  SCHOOL = 'school-graduate',
  COLLEGE = 'college-graduate',
}
