import React, { FC } from 'react';
import parse from 'html-react-parser';
import Button from '../../form-controls/Button';
import { ButtonType } from '../../../types/common';

interface PromptProps {
  classVisibility: string;
  textContent: string[];
  onClick: () => void;
}

export const Prompt: FC<PromptProps> = ({
  classVisibility,
  textContent,
  onClick,
}) => {
  const firstElement = textContent.shift();
  const lastElement = textContent.pop();

  return (
    <div className={classVisibility}>
      <Button
        className="button-close"
        type={ButtonType.BUTTON}
        onClick={onClick}
      >
        <span className="close-icon" />
      </Button>
      <div className="header-prompt-detail-title">
        {firstElement && firstElement}
      </div>
      <ul className="items-list">
        {textContent.map((value, index) => (
          <li key={index}>{parse(value)}</li>
        ))}
      </ul>
      <span className="header-prompt-detail">
        {lastElement && parse(lastElement)}
      </span>
    </div>
  );
};
