import React, { FC } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Field, FieldProps } from 'formik';
import { composeClasses as cc } from '../../../helpers/common';

export const SearchField: FC<TextFieldProps> = ({ ...props }) => (
  <Field name="search">
    {({ field }: FieldProps) => (
      <TextField
        label="Знайти освітню пропозицію"
        className={cc('search-input', { 'has-value': field.value })}
        {...field}
        {...props}
      />
    )}
  </Field>
);

SearchField.displayName = 'TextField';
