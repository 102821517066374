import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { BrowserHistory } from 'history';
import reportWebVitals from './reportWebVitals';
import history from './helpers/history';
import App from './components/App';
import store from './store/store';
import { MainPageResponseData } from './types/common';

import './styles/main.scss';

export interface Ssr {
  SSR_DATA: MainPageResponseData;
}

if (process.env.REACT_APP_USE_CSR === 'true') {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <Provider store={store}>
      <Router history={history as BrowserHistory}>
        <App />
      </Router>
    </Provider>,
  );
} else {
  ReactDOM.hydrateRoot(
    document.getElementById('root') as Element,
    <Provider store={store}>
      <Router history={history as BrowserHistory}>
        <App />
      </Router>
    </Provider>,
  );
}
reportWebVitals();
