import { FC, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFavorite } from '../../../hooks/useFavorite';
import Button from '../../form-controls/Button';
import {
  composeClasses as cc,
  formatPhoneNumber,
} from '../../../helpers/common';
import nixLogo from '../../../assets/img/nix-logo.svg';
import instagramBlack from '../../../assets/img/instagram-black.svg';
import telegramBlack from '../../../assets/img/telegram-black.svg';
import facebookBlack from '../../../assets/img/facebook-black.svg';
import phoneBlack from '../../../assets/img/phone-black.svg';
import site from '../../../assets/img/site.svg';
import { ButtonType, PropositionFullData } from '../../../types/common';
import { CALCULATOR_DEFAULT_ROUTE } from '../../../constants/routes';

interface EducationalProgramModalProps {
  program: PropositionFullData;
  onClickClose: () => void;
  isCalculatorPage: boolean;
}

export const EducationalProgramModal: FC<EducationalProgramModalProps> = ({
  program,
  onClickClose,
  isCalculatorPage,
}) => {
  const {
    id,
    localization,
    nixPartnership,
    department,
    speciality,
    passScore,
    contractPrice,
    detailsLink,
    contactPhone,
    govOrder,
    enrolleeScore,
  } = program;
  const { favoritePropositionIds, toggleFavoriteProposition } = useFavorite();

  const nav = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    if (isCalculatorPage && !pathname.includes('prop')) {
      onClickClose();
    }
  }, [pathname]);

  const closeHandler = () => {
    isCalculatorPage && nav(-1);
    onClickClose();
  };

  const isActive = favoritePropositionIds.includes(id);

  return (
    <div
      className={cc('degree-info', {
        'is-active': isActive,
      })}
    >
      <div className="modal-close" onClick={closeHandler} />
      <div className="degree-info-header">
        <div className="degree-info-partner">
          {nixPartnership && (
            <div className="degree-info-partner-label">
              <span className="degree-info-partner-text">Партнер</span>
              <img src={nixLogo} alt="nix logo" />
            </div>
          )}
          <Button
            className="button-star"
            onClick={() => toggleFavoriteProposition(id)}
          >
            <span
              className={cc('star-icon', {
                'is-active': isActive,
              })}
            />
          </Button>
        </div>
        <span className="degree-info-title">{localization.ua.title}</span>
      </div>
      <div className="degree-info-holder">
        <div className="degree-info-university-list">
          <div className="degree-info-university">
            <div className="degree-info-university-label">Назва ЗВО:</div>
            <div className="degree-info-name">
              {department.university.localization.ua.title}
            </div>
          </div>
          <div className="degree-info-university">
            <div className="degree-info-university-label">
              Випускова кафедра:
            </div>
            <div className="degree-info-name">
              {department.localization.ua.title}
            </div>
          </div>
          <div className="degree-info-university">
            <div className="degree-info-university-label">Спеціальність:</div>
            <div className="degree-info-name">
              {speciality.code}
              {' '}
              -
              {` ${speciality.localization.ua.title}`}
            </div>
          </div>
          <div className="degree-info-university">
            <div className="degree-info-university-label">Освітній рівень:</div>
            <div className="degree-info-name">Бакалавр</div>
          </div>
          <div className="degree-info-university">
            <div className="degree-info-university-label">Термін навчання:</div>
            <div className="degree-info-name">
              {localization.ua.studyPeriod}
            </div>
          </div>
        </div>
        <div className="degree-info-results">
          <div className="degree-info-result minimum-result">
            {passScore && (
              <>
                <span className="degree-info-result-label">Прохідний бал:</span>
                <span className="degree-info-result-value">{passScore}</span>
                <div className="minimum-result-info">
                  <Button className="button-question">
                    <span className="question-icon" />
                  </Button>
                  <div className="question-helper is-hide">
                    <span>
                      Бал абітурієнта, який пройшов на бюджет останнім (на
                      загальних умовах) в 2024 році на конкретну конкурсну
                      пропозицію
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="degree-info-result">
            <span className="degree-info-result-label">Ваш бал:</span>
            {enrolleeScore ? (
              <span className="degree-info-result-value">{enrolleeScore}</span>
            ) : (
              <Link
                className="degree-info-result-link"
                to={CALCULATOR_DEFAULT_ROUTE}
              >
                Розрахувати
              </Link>
            )}
          </div>
          <div className="degree-info-result">
            <span className="degree-info-result-label">
              Кількість бюджетних місць
            </span>
            <span className="degree-info-result-value">{govOrder}</span>
          </div>
          <div className="degree-info-result">
            <span className="degree-info-result-label">
              Вартість навчання(грн.):
            </span>
            <span className="degree-info-result-value">{contractPrice}</span>
            <div className="minimum-result-info">
              <Button className="button-question">
                <span className="question-icon" />
              </Button>
              <div className="question-helper is-hide">
                <span>Вартість за весь термін навчання</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {localization.ua.description && (
        <div className="degree-info-explain">{localization.ua.description}</div>
      )}
      <div className="degree-info-more">
        <div className="degree-image" />
        <div className="degree-info-contacts-holder">
          <div className="degree-info-contacts-title">Контакти</div>
          <div className="degree-info-contacts">
            <div className="degree-info-contact">
              <img className="phone" src={phoneBlack} alt="phone" />
              <a href={`tel:${contactPhone}`} target="_blank">
                {formatPhoneNumber(contactPhone)}
              </a>
            </div>
            {department.webSite && (
              <div className="degree-info-contact">
                <img className="site" src={site} alt="Website" />
                <a
                  className="degree-site"
                  href={department.webSite}
                  target="_blank"
                >
                  {department.webSite}
                </a>
              </div>
            )}
          </div>
          <div className="footer-social">
            {(department.telegram
              || department.instagram
              || department.facebook) && (
              <div className="degree-info-social-title">
                Доєднуйтесь до нас
                {' '}
              </div>
            )}
            <div className="social-wrapper">
              {department.facebook && (
                <a
                  className="social-link"
                  href={department.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebookBlack} alt="facebook" />
                </a>
              )}
              {department.instagram && (
                <a
                  className="social-link"
                  href={department.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagramBlack} alt="instagram" />
                </a>
              )}
              {department.telegram && (
                <a
                  className="social-link"
                  href={department.telegram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={telegramBlack} alt="telegram" />
                </a>
              )}
            </div>
          </div>
          {detailsLink && (
            <a href={detailsLink} target="_blank">
              <Button
                type={ButtonType.BUTTON}
                className="button-link responsive"
              >
                Переглянути освітню програму
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
