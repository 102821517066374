import { useDispatch, useSelector } from 'react-redux';
import { useHash } from './useHash';
import { RootStore } from '../store/store';
import { addToFavorite, removeFromFavorite } from '../store/propositionsSlice';
import { Action } from '../types/common';
import { reportAction } from '../api/actions';

interface UseFavorite {
  favoritePropositionIds: number[];
  toggleFavoriteProposition: (id: number) => void;
}

export const useFavorite = (): UseFavorite => {
  const favoritePropositionIds = useSelector(
    ({ propositions: statePropositions }: RootStore) =>
      statePropositions.favoritePropositionIds,
  );
  const dispatch = useDispatch();
  const { getUserHash } = useHash();

  const toggleFavoriteProposition = (id: number) => {
    if (favoritePropositionIds.includes(id)) {
      dispatch(removeFromFavorite(id));
    } else {
      dispatch(addToFavorite(id));

      reportAction({
        propositionId: id,
        hash: getUserHash(),
        action: Action.LIKED,
      });
    }
  };

  return { favoritePropositionIds, toggleFavoriteProposition };
};
