import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../store/store';
import LayoutTabs from '../LayoutTabs';
import { PropositionFullData, Sort, SortType } from '../../types/common';
import { SortingContext } from '../../contexts/sortingContext';

interface PropositionControlProps {
  issetMinScore: PropositionFullData | undefined;
  universities: string[];
}

export const PropositionControl = forwardRef<
  HTMLDivElement,
  PropositionControlProps
>(({ issetMinScore, universities }, ref) => {
  const { budget, contract } = useSelector(
    (state: RootStore) => state.propositions,
  );
  const [sort, setSort] = useState<Sort>({ speciality: null, score: null });

  const setSpecialitySort = (sortType: SortType) => {
    setSort({ ...sort, speciality: sortType });
  };

  const setScoreSort = (sortType: SortType) => {
    setSort({ ...sort, score: sortType });
  };

  useEffect(() => {
    if (budget.length || contract.length) {
      window.dataLayer.push({ event: 'CalculationSuccess' });
    }
  }, [budget, contract]);

  return (
    <SortingContext.Provider value={{ sort, setSpecialitySort, setScoreSort }}>
      <div ref={ref} className="proposition-wrapper">
        <h3 className="page-subtitle">Доступні конкурсні пропозиції</h3>
        <div className="degree-controls">
          <LayoutTabs
            universities={universities}
            issetMinScore={issetMinScore}
          />
        </div>
      </div>
    </SortingContext.Provider>
  );
});

PropositionControl.displayName = 'PropositionControl';
