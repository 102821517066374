import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import ItswayLogo from '../../../assets/img/logo.svg';
import NixLogo from '../../../assets/img/nixLogo.svg';
import Menu from '../Menu';
import { PROFESSIONS_ROUTE } from '../../../constants/routes';
import { ON_CLICK_LOGO } from '../../../constants/events';

const HeaderNav: FC = () => {
  const onClickLogo = () => {
    window.dispatchEvent(new Event(ON_CLICK_LOGO));
  };

  return (
    <div className="header-nav-wrapper">
      <div className="header-nav">
        <Link
          to={PROFESSIONS_ROUTE}
          className="header-logo-itsway"
          onClick={onClickLogo}
        >
          <img
            className="header-logo"
            src={ItswayLogo}
            alt="DoZVO"
            width={104}
            height={32}
          />
        </Link>
        <Menu />
        <a
          className="header-logo-link"
          href="https://www.nixsolutions.com/ru/"
          target="_blank"
        >
          <img
            id="nix-link"
            src={NixLogo}
            className="header-logo-nix"
            alt="nix logo"
          />
        </a>
      </div>
    </div>
  );
};

export default HeaderNav;
