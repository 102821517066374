import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

interface PageTitleProps {
  title: string;
  description: string;
  url: string;
  image: string;
  alt: string;
  canonical?: string;
  disableIndex?: boolean;
}

const Meta = memo<PageTitleProps>(
  ({
    title, description, url, image, alt, canonical, disableIndex,
  }) => (
    <Helmet>
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta name="description" content={description} />
      <meta
        property="og:description"
        name="description"
        content={description}
      />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content="ua" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={alt} />
      {canonical && <link rel="canonical" href={canonical} />}
      {disableIndex && <meta name="robots" content="noindex, follow" />}
      <script type="application/ld+json">
        {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "ITs WAY",
            "url": "https://itsway.nixsolutions.com/",
            "email": "education@nixsolutions.com",
            "logo": "https://itsway.nixsolutions.com/static/media/logo.f730f2129dcf072558559b93f81dad4c.svg",
            "description": "Знайди свій ідеальний шлях в ІТ-кар'єрі за допомогою онлайн-асистента ITs WAY ✔ На нашому сайті ти дізнаєшся всю необхідну інформацію про кожну ІТ-професію та про те, яка з них підходить тобі найбільше.",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Харків",
              "addressCountry": "Україна"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+38 (067) 370-21-29",
              "contactType": "customer service"
            },
            "sameAs": [
              "https://www.instagram.com/nix_community/",
              "https://twitter.com/nix_community",
              "https://www.facebook.com/nix.community"
            ]
          }
        `}
      </script>
    </Helmet>
  ),
);

export default Meta;
