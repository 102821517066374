import React, { FC } from 'react';
import { Field, FieldProps } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { FormControl, Input, SelectChangeEvent } from '@mui/material';
import {
  Speciality,
  SelectSpecialities,
  SubjectResponse,
  SelectSubjects,
} from '../../../types/common';
import { ValidationErrors } from '../ValidationErrors/ValidationErrors';

interface SpecialitySelectProps {
  selectName: string;
  selectTitle: string;
  selectItems:
    | Speciality[]
    | SelectSpecialities[]
    | SelectSubjects[]
    | SubjectResponse[];
  selectedValue?: string[] | string;
  onChange?: (event: SelectChangeEvent<string[] | string>) => void;
  inputHasError?: boolean;
}

export const SpecialitySelect: FC<SpecialitySelectProps> = ({
  selectName,
  selectTitle,
  selectItems,
  selectedValue,
  onChange,
  inputHasError,
}) => (
  <FormControl className="select-holder" fullWidth>
    <InputLabel className="inputLabel" shrink={false}>
      {selectTitle}
    </InputLabel>
    <Field name={selectName}>
      {(props: FieldProps) => (
        <div className="select-content">
          <Select
            input={<Input />}
            name="select"
            className={`subject-select ${
              props.meta.error && props.meta.touched ? 'is-invalid' : ''
            } ${selectedValue ? 'has-value' : ''}`}
            value={selectedValue}
            onChange={onChange}
            multiple={false}
            {...props}
            inputProps={{ IconComponent: () => null }}
          >
            {'code' in selectItems
              ? (selectItems as SelectSpecialities[]).map((select) => (
                <MenuItem
                  className="subject-option"
                  value={select.code}
                  key={select.code}
                >
                  {select.code ? `${select.code}\u00A0\u00A0\u00A0` : null}
                  {select.localization.ua.title}
                </MenuItem>
              ))
              : (selectItems as SubjectResponse[]).map((select) => (
                <MenuItem
                  className="subject-option"
                  value={select.alias}
                  key={select.localization.ua.title}
                >
                  {select.localization.ua.title}
                </MenuItem>
              ))}
          </Select>
          {props.meta.touched && props.meta.error && (
            <ValidationErrors
              errorId={`errors-${selectName}`}
              errorContent={props.meta.error}
            />
          )}
        </div>
      )}
    </Field>
  </FormControl>
);
