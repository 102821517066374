import axios from 'axios';

const isServer = typeof window === 'undefined';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

if (!isServer) {
  instance.interceptors.response.use(
    ({ data }) => data.data,
    (error) => Promise.reject(error),
  );
} else {
  instance.interceptors.response.use(
    ({ data }) => data.data,
    () => ({}),
  );
}

export default instance;
