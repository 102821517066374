import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Preloader from '../../common/Preloader';
import EducationalProgramModal from '../../common/EducationalProgramModal';
import Layout from '../../Layout';
import Meta from '../../Meta';
import { SelectedProgramContext } from '../../../contexts/selectedProgramContext';
import { composeClasses as cc } from '../../../helpers/common';
import { PREVIOUS_TEST } from '../../../constants/localStorage';
import { resetPropositionsData } from '../../../store/propositionsSlice';
import { useCalculatorPageData } from '../../../contexts/calculatedPropositionContext';
import { PropositionFullData } from '../../../types/common';

export const CalculatorPage: FC = () => {
  const { isLoading, data: dataPage } = useCalculatorPageData();

  const [selectedProgram, setSelectedProgram] = useState<PropositionFullData | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    localStorage.removeItem(PREVIOUS_TEST);

    dispatch(resetPropositionsData());

    window.dataLayer.push({
      event: 'CalculatorPage',
    });
  }, []);

  return (
    <>
      <Meta
        alt="Калькулятор"
        description="Розрахуйте бали НМТ для IT-спеціальності за допомогою нашого калькулятора. Порахуйте середній бал НМТ та дізнайтеся, як розрахувати конкурсний бал."
        image="https://itsway.nixsolutions.com/static/media/header-img.960718c2ad6e3cb80bfc.png"
        title="Калькулятор балів НМТ: розрахувати конкурсний бал абітурієнта | ITs WAY"
        url="https://itsway.nixsolutions.com/calculator"
        canonical="https://itsway.nixsolutions.com/calculator"
      />
      {isLoading && <Preloader />}
      {!isLoading && dataPage && (
        <>
          {selectedProgram && (
            <EducationalProgramModal
              program={selectedProgram}
              onClickClose={() => setSelectedProgram(null)}
              isCalculatorPage
            />
          )}
          <div className={cc('', { 'is-hide': !!selectedProgram })}>
            <SelectedProgramContext.Provider
              value={{ selectedProgram, setSelectedProgram }}
            >
              <Layout
                hints={dataPage.hints}
                about={dataPage.sections}
                stages={dataPage.stages}
              />
            </SelectedProgramContext.Provider>
          </div>
        </>
      )}
    </>
  );
};
