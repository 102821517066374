import { FC } from 'react';
import Button from '../../form-controls/Button';
import { ButtonType, SortType } from '../../../types/common';

interface TableSortForDesktopProps {
  sortType: SortType | null;
  setSort: (value: SortType) => void;
}

export const TableSortForDesktop: FC<TableSortForDesktopProps> = ({
  sortType,
  setSort,
}) => (
  <div className="table-sort">
    <Button
      className="button-sort"
      type={ButtonType.BUTTON}
      onClick={() => setSort(SortType.ASC)}
    >
      <span
        className={
          sortType === SortType.ASC ? 'sort-up-icon is-active' : 'sort-up-icon'
        }
      />
    </Button>
    <Button
      className="button-sort"
      type={ButtonType.BUTTON}
      onClick={() => setSort(SortType.DESC)}
    >
      <span
        className={
          sortType === SortType.DESC
            ? 'sort-down-icon is-active'
            : 'sort-down-icon'
        }
      />
    </Button>
  </div>
);
