import { useSelector } from 'react-redux';
import { RootStore } from '../store/store';
import { useCalculatorPageData } from '../contexts/calculatedPropositionContext';

export const useInitialDataLoaded = () => {
  const isProfessionsLoaded = useSelector(
    ({ professions }: RootStore) => professions.isLoaded,
  );

  const { isLoading } = useCalculatorPageData();

  return isProfessionsLoaded && !isLoading;
};
