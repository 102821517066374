import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Link as Scroll } from 'react-scroll';
import { useSelector } from 'react-redux';
import { composeClasses as cc } from '../../../helpers/common';
import { PROFESSIONS_ROUTE } from '../../../constants/routes';
import { Navigation } from '../../../types/common';
import { RootStore } from '../../../store/store';

interface ProfessionsNavButtonProps {
  activePage: string;
  openActiveCardForLink: (event: { preventDefault: () => void }) => void;
  openActiveCardForScroll: () => void;
  onHoverProfessions: () => void;
  title: string;
}

const ProfessionsNavButton: FC<ProfessionsNavButtonProps> = ({
  activePage,
  openActiveCardForLink,
  openActiveCardForScroll,
  onHoverProfessions,
  title,
}) => {
  const professionShowStatus = useSelector(
    ({ professions: stateProfessions }: RootStore) =>
      stateProfessions.professionShowStatus,
  );

  return !professionShowStatus ? (
    <Link
      to={PROFESSIONS_ROUTE}
      className={cc('menu-link menu-link-professions', {
        active: activePage === PROFESSIONS_ROUTE,
      })}
      onMouseEnter={onHoverProfessions}
      onClick={openActiveCardForLink}
    >
      {title}
    </Link>
  ) : (
    <Scroll
      className={cc('menu-link menu-link-professions', {
        active: activePage === PROFESSIONS_ROUTE,
      })}
      to={Navigation.CARD_DETAILS}
      offset={-150}
      duration={300}
      smooth
      onMouseEnter={onHoverProfessions}
      onClick={openActiveCardForScroll}
    >
      {title}
    </Scroll>
  );
};
export default ProfessionsNavButton;
