import { createContext } from 'react';
import { PropositionFullData } from '../types/common';

interface SelectedProgramContext {
  selectedProgram: PropositionFullData | null;
  setSelectedProgram: (program: PropositionFullData) => void;
}

export const SelectedProgramContext = createContext<SelectedProgramContext>({
  selectedProgram: null,
  setSelectedProgram: (program: PropositionFullData) => undefined,
});
