import { FC, MouseEvent } from 'react';
import Button from '../../form-controls/Button';
import { ShortPropositionData } from '../../../types/common';
import { composeClasses as cc } from '../../../helpers/common';
import checkMarkImg from '../../../assets/img/check-mark-2.svg';

interface TableItemsProps extends ShortPropositionData {
  isActive: boolean;
  onClick: () => void;
  onClickStar: () => void;
}

export const TableItems: FC<TableItemsProps> = ({
  id,
  ua,
  speciality,
  nixPartnership,
  department,
  passScore,
  enrolleeScore,
  isActive,
  onClick,
  onClickStar,
}) => {
  const handleOnClickStar = (event: MouseEvent) => {
    event.stopPropagation();

    onClickStar();
  };

  return (
    <tr
      className={cc('table-row', { 'is-active': isActive })}
      onClick={onClick}
    >
      <td className="table-col">
        <p className="table-degree-link">{ua.title}</p>
      </td>
      <td className="table-col">
        {department.university.localization.ua.title}
      </td>
      <td className="table-col">{speciality?.localization.ua.alias}</td>
      <td className="table-col">{enrolleeScore}</td>
      <td className="table-col">{passScore}</td>
      <td className="table-col">
        {nixPartnership && <img src={checkMarkImg} alt="marker" />}
      </td>
      <td className="table-col">
        <Button className="button-star" onClick={handleOnClickStar}>
          <span className="star-icon" />
        </Button>
      </td>
    </tr>
  );
};
