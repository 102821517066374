import React, { FC, useState } from 'react';
import Tabs from '../../form-controls/FormTabs';
import Prompt from '../Prompt';
import headerImg from '../../../assets/img/header-img.svg';
import infoImg from '../../../assets/img/info.svg';
import { Calculators, PromptsData } from '../../../types/common';
import ProfessionsPageTitle from '../../professions/ProfessionsPageTitle';

enum PromptVisibility {
  HIDE = 'header-prompt is-hide',
  SHOW = 'header-prompt',
}

enum PromptTitle {
  HIDDEN = 'Дивитися підказку',
  SHOWED = 'Приховати підказку',
}

interface HeaderProps {
  calculators: Calculators;
  hints: PromptsData;
}

export const Header: FC<HeaderProps> = ({ calculators, hints }) => {
  const [visibility, setVisibility] = useState<string>(PromptVisibility.HIDE);
  const [promptTitle, setPromptTitle] = useState<string>(PromptTitle.HIDDEN);

  const regex = /<br\s*\/?>/gi;
  const strWithoutSpaces = hints.calculator.localization.ua.description.replace(
    regex,
    '',
  );
  const promptItemArr = strWithoutSpaces.split('>');

  const handleClick = () => {
    if (visibility === PromptVisibility.HIDE) {
      setVisibility(PromptVisibility.SHOW);
      setPromptTitle(PromptTitle.SHOWED);
    } else {
      setVisibility(PromptVisibility.HIDE);
      setPromptTitle(PromptTitle.HIDDEN);
    }
  };

  return (
    <div className="header-content">
      <div className="header-info">
        <div className="title-arrow secondary" />
        <ProfessionsPageTitle title="Цей калькулятор розрахує за тебе конкурсний бал." />
        <div className="page-caption">
          Ти дізнаєшся свої шанси стати студентом кращих ІТ-університетів
          країни.
          <div className="header-subtitle-holder">
            <button
              className="header-subtitle"
              type="button"
              onClick={handleClick}
            >
              <img src={infoImg} alt="information" />
              <span className="header-prompt-title">{promptTitle}</span>
            </button>
            <Prompt
              classVisibility={visibility}
              textContent={promptItemArr.slice(0, promptItemArr.length - 1)}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
      <div className="header-holder">
        <img className="header-img" src={headerImg} alt="headerImage" />
        <Tabs calculators={calculators} />
      </div>
    </div>
  );
};
