import React, {
  FC, useEffect, useRef, useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropositionControl from '../PropositionControl';
import Slider from '../common/Slider';
import Letter from '../common/Letter';
import { RootStore } from '../../store/store';
import { PromptsContext } from '../../contexts/promptsContext';
import { FilterContext } from '../../contexts/filterContext';
import { monDocs } from '../../constants/monDocs';
import {
  Filters,
  PromptsData,
  PropositionFullData,
  Stage,
  AboutItemProps,
} from '../../types/common';
import Header from '../common/Header';
import { useCalculatorPageData } from '../../contexts/calculatedPropositionContext';
import { Expandable } from '../common/Expandable/Expandable';

const initialFilters: Filters = {
  universitiesFilter: [],
  selectionsFilter: false,
  partnersFilter: false,
};

interface LayoutProps {
  hints: PromptsData;
  about: AboutItemProps[];
  stages: Stage[];
}

export const Layout: FC<LayoutProps> = ({ hints, stages, about }) => {
  const { contract, budget } = useSelector(
    (state: RootStore) => state.propositions,
  );
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [universities, setUniversities] = useState<string[]>([]);

  const { data: dataPage } = useCalculatorPageData();

  const CONTRACT_LENGTH = contract.length;
  const BUDGET_LENGTH = budget.length;

  const getFirstPassScore = (propositions: PropositionFullData[]) =>
    propositions.find(({ passScore }) => passScore);

  const toggleUniversityTitle = (title: string) => {
    if (filters.universitiesFilter.includes(title)) {
      setFilters((prevState) => ({
        ...prevState,
        universitiesFilter: prevState.universitiesFilter.filter(
          (prevTitle) => prevTitle !== title,
        ),
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        universitiesFilter: [...prevState.universitiesFilter, title],
      }));
    }
  };

  const toggleSelectionsFilter = () => {
    setFilters((prevState) => ({
      ...prevState,
      selectionsFilter: !prevState.selectionsFilter,
    }));
  };

  const togglePartnersFilter = () => {
    setFilters((prevState) => ({
      ...prevState,
      partnersFilter: !prevState.partnersFilter,
    }));
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tempUniversities: string[] = [];

    [...budget, ...contract].forEach(({ department }) => {
      const { title } = department.university.localization.ua;

      if (!tempUniversities.includes(title)) {
        tempUniversities.push(title);
      }
    });

    setUniversities(tempUniversities);
    setFilters({
      ...initialFilters,
      universitiesFilter: tempUniversities,
    });

    if (budget.length || contract.length) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [budget, contract]);

  return (
    <PromptsContext.Provider
      value={{
        calculator: hints.calculator.localization.ua.description,
        minScore: hints.minScore.localization.ua.description,
        restriction: hints.restriction.localization.ua.description,
      }}
    >
      <FilterContext.Provider
        value={{
          filters,
          toggleUniversityTitle,
          toggleSelectionsFilter,
          togglePartnersFilter,
        }}
      >
        <main className="main">
          {dataPage ? (
            <Header calculators={dataPage.calculators} hints={dataPage.hints} />
          ) : null}
          {(CONTRACT_LENGTH !== 0 || BUDGET_LENGTH !== 0) && (
            <PropositionControl
              ref={containerRef}
              issetMinScore={
                getFirstPassScore(budget) || getFirstPassScore(contract)
              }
              universities={universities}
            />
          )}
          <Letter
            letterLink={about[0].url}
            letterDescription={about[0].localization[0].content}
            letterTitle={about[0].localization[0].title}
          />
          <Slider stages={stages} />
          <div className="more-info">
            *Більш детальна інформація доступна в
            {' '}
            <a className="more-info-link" href={monDocs} target="_blank">
              офіційних документах МОН
            </a>
            .
          </div>
          <Expandable>
            <div className="calculator-description">
              <div className="calculator-description-section">
                <h2 className="calculator-description-title">
                  Використовуй калькулятор балів НМТ для вибору університету
                </h2>
                <div className="calculator-description-text">
                  <p>
                    На платформі ITs WAY ти можеш ознайомитись із провідними
                    вишами України. Тут зібрана інформація про університети
                    Києва, Харкова, Полтави та інших міст, де навчають майбутніх
                    ІТ-фахівців.
                  </p>
                  <p>
                    Для цього використовуй калькулятор балів НМТ. Вкажи
                    результати НМТ у відповідних строках, додай сертифікат про
                    закінчення підготовчих курсів (за наявності). Розрахунок
                    середнього балу НМТ відбув
                  </p>
                  <p>
                    Якщо склав ЗНО, теж скористайся сервісом. У потрібні строки
                    додай бали ЗНО, щоб побачити перелік вишів для вступу.
                  </p>
                  <p>Пропонуємо розрахувати бал НМТ на платформі ITs WAY.</p>
                </div>
              </div>
              <div className="calculator-description-section">
                <h2 className="calculator-description-title">
                  Розрахунок середнього балу НМТ покаже твої шанси вступити до
                  університету
                </h2>
                <div className="calculator-description-text">
                  <p>
                    Калькулятор використовує статистику вступної кампанії за
                    попередній рік для якісних результатів. Калькулятор балів
                    НМТ визначає конкурсний бал, з яким є висока ймовірність
                    зарахування на бюджет.
                  </p>
                  <p>
                    Пам’ятай, що конкурсний бал для вступу на одну й ту саму
                    спеціальність у різних вишах чи на різні спеціальності в
                    одному ЗВО може відрізнятися.
                  </p>
                  <p>
                    Обирай найбільш цікавий для себе напрям! Розрахунок
                    середнього балу НМТ — це твій орієнтир у виборі професії.
                    Після того, як сервіс допоможе розрахувати бал НМТ,
                    додатково можеш поспілкуватись із представниками ЗВО.
                    Контакти побачиш в описі спеціальностей.
                  </p>
                </div>
              </div>
            </div>
          </Expandable>
        </main>
      </FilterContext.Provider>
    </PromptsContext.Provider>
  );
};
