import React, {
  FC, useCallback, useEffect, useRef,
} from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import { Stage } from '../../../types/common';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { composeClasses as cc } from '../../../helpers/common';

export interface StagesProps {
  stages: Stage[];
}

export const CustomSlider: FC<StagesProps> = ({ stages }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  };

  const slider = useRef<Slider>(null);

  useEffect(() => {
    // eslint-disable-next-line max-len
    const nearestStageIndex = stages.findIndex(
      (stage) => moment().format() <= moment(stage.endsAt).format(),
    );

    if (nearestStageIndex >= 0) {
      slider.current?.slickGoTo(nearestStageIndex);
    }
  }, [stages]);

  const onPrev = useCallback(() => {
    slider.current?.slickPrev();
  }, []);

  const onNext = useCallback(() => {
    slider.current?.slickNext();
  }, []);

  return (
    <div className="slider">
      <div className="slider-head">
        <div className="slider-title">Етапи вступної кампанії у 2024 р.</div>
        <div className="slider-controls">
          <div
            className="slider-control slider-control-prev"
            onClick={onPrev}
          />
          <div
            className="slider-control slider-control-next"
            onClick={onNext}
          />
        </div>
      </div>
      <Slider ref={slider} {...settings}>
        {stages.map((stage) => {
          const currentDate = moment().format();
          const stageStartDate = moment(stage.startsAt).format();
          const stageEndDate = moment(stage.endsAt).format();

          const isDisabled = stageEndDate < currentDate;
          const isCurrent = stageStartDate <= currentDate && currentDate <= stageEndDate;
          const isFuture = stageStartDate > currentDate;

          return (
            <div
              className={cc('slider-item', {
                'is-disabled': isDisabled,
                'is-current': isCurrent,
                'is-future': isFuture,
              })}
              key={stage.stageNumber}
            >
              <div className="slider-stage-holder">
                <div className="slider-stage">{stage.stageNumber}</div>
              </div>
              <div className="slider-date">{stage.localization.ua.timing}</div>
              <div className="slider-text">{stage.localization.ua.about}</div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
