import {
  FC, useContext, useEffect, useMemo,
} from 'react';
import { PropositionFullData, SortType } from '../../../types/common';
import { SortingContext } from '../../../contexts/sortingContext';
import closeImg from '../../../assets/img/close-1.svg';

interface SortMenuProps {
  setIsShowed: (value: boolean) => void;
  issetMinScore: PropositionFullData | undefined;
  isIncreaseSortOptions: boolean;
  filterParentWidth?: number;
}

export const SortMenu: FC<SortMenuProps> = ({
  setIsShowed,
  issetMinScore,
  isIncreaseSortOptions,
  filterParentWidth,
}) => {
  const { setScoreSort, setSpecialitySort } = useContext(SortingContext);

  const handleClickOutside = () => {
    setIsShowed(false);
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const style = useMemo(() => ({ width: filterParentWidth || undefined }), []);

  return (
    <div className="filters" style={style}>
      <span className="filters-close" onClick={handleClickOutside}>
        <img src={closeImg} alt="close" />
      </span>
      <div className="sort-title">Сортувати за...</div>
      <div className="sort-items">
        {issetMinScore && (
          <div
            className="sort-item"
            onClick={() =>
              setScoreSort(isIncreaseSortOptions ? SortType.ASC : SortType.DESC)}
          >
            {isIncreaseSortOptions ? 'Зростанням балу' : 'Спаданням балу'}
          </div>
        )}
        <div
          className="sort-item"
          onClick={() => {
            if (!setSpecialitySort) {
              return;
            }
            setSpecialitySort(
              isIncreaseSortOptions ? SortType.ASC : SortType.DESC,
            );
          }}
        >
          {isIncreaseSortOptions
            ? 'Зростанням спеціальності'
            : 'Спаданням спеціальності'}
        </div>
      </div>
    </div>
  );
};
