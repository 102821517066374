import { createSlice } from '@reduxjs/toolkit';
import { Footer } from '../types/footer';
import inIcon from '../assets/img/in.svg';
import twIcon from '../assets/img/tw.svg';
import fbIcon from '../assets/img/fb.svg';

interface InitialFooterState {
  footer: Footer;
}

const initialFooterState: InitialFooterState = {
  footer: {
    email: 'education@nixsolutions.com',
    phone: '+38 (067) 447-18-97',
    socialMedia: [
      {
        logo: inIcon,
        link: 'https://www.instagram.com/nix_ukraine/',
        priority: 1,
        alias: 'in',
      },
      {
        logo: twIcon,
        link: 'https://twitter.com/nix_ukraine',
        priority: 2,
        alias: 'tw',
      },
      {
        logo: fbIcon,
        link: 'https://www.facebook.com/nix.ukraine/',
        priority: 3,
        alias: 'fb',
      },
    ],
  },
};

export const footerSlice = createSlice({
  name: 'footer',
  initialState: initialFooterState,
  reducers: {},
});

export default footerSlice.reducer;
