import React, { FC } from 'react';
import SchoolTab from '../SchoolTab';
import { Calculators } from '../../../types/common';

interface FormTabsProps {
  calculators: Calculators;
}

export const FormTabs: FC<FormTabsProps> = ({ calculators }) => (
  <div className="header-form">
    <h2 className="header-form-title">Бали НМТ:</h2>
    <div className="header-form-subtitle">
      Введіть свої бали ЗНО за шкалою від 100 до 200 для розрахунку конкурсного
      балу.
    </div>
    <SchoolTab
      subjects={calculators.school.subjects}
      courses={calculators.school.courses}
      active
    />
  </div>
);
