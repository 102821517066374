import React, { memo } from 'react';

export const Preloader = memo(() => (
  <div className="wrapper">
    <div className="circles">
      <div className="circle circle--1">&nbsp;</div>
      <div className="circle circle--2">&nbsp;</div>
      <div className="circle circle--3">&nbsp;</div>
      <div className="circle circle--4">&nbsp;</div>
    </div>
  </div>
));
