import React, { FC, ReactNode } from 'react';
import { Field, FieldProps } from 'formik';
import { ValidationErrors } from '../ValidationErrors/ValidationErrors';
import { InputType } from '../../../types/common';

interface InputProps {
  fieldName: string;
  type: string;
  labelValue?: string | ReactNode;
  className?: string;
  placeholder?: string;
  onClick?: () => void;
  isSelect?: boolean;
  errorClassName?: string;
  customErrorMessage?: string;
}

export const Input: FC<InputProps> = ({
  fieldName,
  type,
  labelValue,
  onClick,
  className = 'subject-number',
  placeholder = 'Твій бал',
  isSelect = false,
  errorClassName,
  customErrorMessage,
}) => (
  <Field name={fieldName}>
    {({ field, meta }: FieldProps) => (
      <div className="subject-wrapper">
        {labelValue ? (
          <label
            className={`subject-name ${
              meta.error && meta.touched && !isSelect ? 'is-invalid' : ''
            }`}
          >
            {labelValue}
          </label>
        ) : null}
        <input
          className={`${className} ${
            customErrorMessage || (meta.error && meta.touched)
              ? 'is-invalid'
              : ''
          }`}
          placeholder={placeholder}
          type={type !== InputType.NUMBER ? type : undefined}
          onClick={onClick}
          {...field}
          onChange={(event) => {
            if (
              type === InputType.NUMBER
              && !/^[0-9]*$/.test(event.target.value)
            ) {
              return;
            }

            field.onChange(event);
          }}
        />
        {(customErrorMessage || (meta.touched && meta.error)) && (
          <ValidationErrors
            errorClassName={errorClassName}
            errorId={`errors-${fieldName}`}
            errorContent={customErrorMessage || meta.error}
          />
        )}
      </div>
    )}
  </Field>
);
