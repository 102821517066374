import { createContext } from 'react';
import { Filters } from '../types/common';

interface FilterContext {
  filters: Filters;
  toggleUniversityTitle: (title: string) => void;
  toggleSelectionsFilter: () => void;
  togglePartnersFilter: () => void;
}

export const FilterContext = createContext<FilterContext>({
  filters: {
    universitiesFilter: [],
    selectionsFilter: false,
    partnersFilter: false,
  },
  toggleUniversityTitle: (title: string) => undefined,
  toggleSelectionsFilter: () => undefined,
  togglePartnersFilter: () => undefined,
});
