import { BrowserHistory, createBrowserHistory } from 'history';

let history: null | BrowserHistory = null;

try {
  history = createBrowserHistory();
} catch {
  /* eslint-disable */
  console.log("It`s normal.");
  /* eslint-enable */
}

export default history;
