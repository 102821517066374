import { createContext } from 'react';

interface PromptsContextType {
  calculator?: string;
  minScore: string;
  restriction?: string;
}

export const PromptsContext = createContext<PromptsContextType>({
  calculator: '',
  minScore: '',
  restriction: '',
});
