import React, { MouseEventHandler, ReactElement } from 'react';

interface TabProps {
  linkTitle: string;
  href: string;
  className: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

function Tab({
  linkTitle, className, href, onClick,
}: TabProps): ReactElement {
  return (
    <a className={className} href={href} onClick={onClick}>
      {linkTitle}
    </a>
  );
}

export default Tab;
