import { FC, MouseEvent } from 'react';
import Button from '../../form-controls/Button';
import { composeClasses as cc } from '../../../helpers/common';
import { ShortPropositionData } from '../../../types/common';
import nixImg from '../../../assets/img/nix-logo.svg';

interface TableCardProps extends ShortPropositionData {
  isActive: boolean;
  onClick: () => void;
  onClickStar: () => void;
}

export const TableCard: FC<TableCardProps> = ({
  id,
  ua,
  speciality,
  nixPartnership,
  department,
  passScore,
  enrolleeScore,
  isActive,
  onClick,
  onClickStar,
}) => {
  const handleOnClickStar = (event: MouseEvent) => {
    event.stopPropagation();

    onClickStar();
  };

  return (
    <div
      className={cc('table-card', { 'is-active': isActive })}
      onClick={onClick}
    >
      <div className="table-card-degree">
        <span className="table-card-degree-number">
          {speciality?.localization.ua.alias}
        </span>
        <div className="table-card-degree-partner">
          {nixPartnership && (
            <img src={nixImg} alt="nix logotype" width={42} height={21} />
          )}
          <Button className="button-star" onClick={handleOnClickStar}>
            <span className={cc('star-icon', { 'is-active': isActive })} />
          </Button>
        </div>
      </div>
      <div className="table-card-degree-name">{ua.title}</div>
      <div className="table-card-university-name" key={id}>
        {department.university.localization.ua.title}
      </div>
      <div className="table-card-result">
        {enrolleeScore && (
          <div className="table-card-mark">
            <span className="table-card-mark-text">Ваш бал</span>
            <span className="table-card-mark-number">{enrolleeScore}</span>
          </div>
        )}
        {passScore && (
          <div className="table-card-mark">
            <span className="table-card-mark-text">Мінімальний бал</span>
            <span className="table-card-mark-number">{passScore}</span>
          </div>
        )}
      </div>
    </div>
  );
};
