import React, {
  createContext, ReactNode, useContext, useEffect,
} from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { getAllContent } from '../api/calculatorsPage';
import { ON_REJECTED } from '../constants/events';
import { MainPageResponseData } from '../types/common';
import { getProfessionMap } from '../api/professionMap';
import { setProfessions } from '../store/professionsSlice';

interface DataContextValue {
  data: MainPageResponseData | undefined;
  isLoading: boolean;
  error: Error | null;
}

const CalculatorPageContext = createContext<DataContextValue | undefined>(
  undefined,
);

export function useCalculatorPageData(): DataContextValue {
  const context = useContext(CalculatorPageContext);
  if (!context) {
    throw new Error('useCalculatorPageData must be used within a DataProvider');
  }
  return context;
}

interface CalculatorPageDataProviderProps {
  children: ReactNode;
}

export function CalculatorPageDataProvider({
  children,
}: CalculatorPageDataProviderProps) {
  const { data, isLoading, error } = useQuery<MainPageResponseData>(
    'calculatedPropositions',
    getAllContent,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getProfessionMap()
      .then((response) => {
        dispatch(setProfessions(response));
      })
      .catch(() => {
        window.dispatchEvent(new Event(ON_REJECTED));
      });
  }, []);

  if (isLoading) {
    return null;
  }

  if (error) {
    window.dispatchEvent(new Event(ON_REJECTED));
  }

  return (
    <CalculatorPageContext.Provider value={{ data, isLoading, error: null }}>
      {children}
    </CalculatorPageContext.Provider>
  );
}
