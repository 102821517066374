import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link as Scroll, scroller } from 'react-scroll';
import { composeClasses as cc } from '../../../helpers/common';
import {
  setActiveProfessionId,
  setProfessions,
  setProfessionShowStatus,
} from '../../../store/professionsSlice';
import { RootStore } from '../../../store/store';
import { setActiveCardIdAndDetailsStatus } from '../../../helpers/professions';
import { PROFESSIONS_ROUTE } from '../../../constants/routes';
import { DEFAULT_ACTIVE_CARD_ID } from '../../../constants/localStorage';
import { ON_HOVER_PROFESSIONS } from '../../../constants/endpoints';
import { SCROLL_OPTIONS } from '../../../constants/scroll';
import { ON_RENDERED_CARD_DETAILS } from '../../../constants/events';
import { ProfessionMap } from '../../../types/professions';
import { Navigation } from '../../../types/common';
import developerIcon from '../../../assets/img/developer-icon-ua.svg';
import qaIcon from '../../../assets/img/qa-icon-ua.svg';
import baIcon from '../../../assets/img/ba-icon-ua.svg';
import devopsIcon from '../../../assets/img/devops-icon-ua.svg';
import designerIcon from '../../../assets/img/designer-icon-ua.svg';
import dataEngineerIcon from '../../../assets/img/data-engineer-icon-ua.svg';
import pmIcon from '../../../assets/img/pm-icon-ua.svg';
import { ProfessionIds } from '../../../constants/professions';

const icons = {
  [ProfessionIds.developer]: developerIcon,
  [ProfessionIds.qa]: qaIcon,
  [ProfessionIds.pm]: pmIcon,
  [ProfessionIds.ba]: baIcon,
  [ProfessionIds.dataEngineer]: dataEngineerIcon,
  [ProfessionIds.devops]: devopsIcon,
  [ProfessionIds.designer]: designerIcon,
};

interface HeaderSubNavProps {
  classNameMenuWrapper: string;
  classNameMenuList: string;
  classNameMenuItem: string;
  onSelect: () => void;
}

const HeaderSubNav: FC<HeaderSubNavProps> = ({
  classNameMenuWrapper,
  classNameMenuList,
  classNameMenuItem,
  onSelect: onSelectFromProps,
}) => {
  const { professions } = useSelector((state: RootStore) => state.professions);
  const professionShowStatus = useSelector(
    ({ professions: stateProfessions }: RootStore) =>
      stateProfessions.professionShowStatus,
  );
  const activeProfession = useSelector(
    ({ professions: stateProfessions }: RootStore) =>
      stateProfessions.activeProfessionId,
  );
  const dispatch = useDispatch();

  const [cards, setCards] = useState<ProfessionMap[]>(professions);
  const [activeId, setActiveId] = useState<string>(DEFAULT_ACTIVE_CARD_ID);
  const [hasMounted, setHasMounted] = useState(false);
  const [isOpenProfessions, setIsOpenProfessions] = useState<boolean>(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setHasMounted(true);
    setActiveId(localStorage.activeCardId);
    setCards(professions);
  }, []);

  useEffect(() => {
    setCards(professions);
  }, [professions]);

  useEffect(() => {
    dispatch(setProfessions(cards));
  }, [activeId]);

  useEffect(() => {
    const setShowProfessions = () => {
      setIsOpenProfessions(true);
    };

    window.addEventListener(ON_HOVER_PROFESSIONS, setShowProfessions);

    return () =>
      window.removeEventListener(ON_HOVER_PROFESSIONS, setShowProfessions);
  }, [isOpenProfessions]);

  const openProfession = (id: string) => {
    setCards((prevState) =>
      prevState.map((card) => ({
        ...card,
        isDisplay: card.id === Number(id),
      })));

    setActiveCardIdAndDetailsStatus(id, 'true');

    if (pathname === PROFESSIONS_ROUTE) {
      dispatch(setProfessionShowStatus(true));
      dispatch(setActiveProfessionId(Number(id)));
    }

    setActiveId(id);

    dispatch(setProfessions(cards));

    if (window.matchMedia('(max-width: 800px)').matches) {
      setIsOpenProfessions(false);
    }

    const scroll = () => {
      pathname === PROFESSIONS_ROUTE
        && scroller.scrollTo(Navigation.CARD_DETAILS, SCROLL_OPTIONS);
    };

    window.addEventListener(ON_RENDERED_CARD_DETAILS, scroll);

    return () => window.removeEventListener(ON_RENDERED_CARD_DETAILS, scroll);
  };

  const onSelect = useCallback((id: string) => {
    onSelectFromProps();
    openProfession(String(id));
  }, []);

  if (!hasMounted) return null;

  return (
    <div className={classNameMenuWrapper}>
      <ul className={classNameMenuList}>
        {cards.map(
          ({
            id,
            localization: {
              ua: { alias },
            },
          }) => {
            // @ts-ignore
            const icon = icons[id];
            const content = (
              <>
                <img
                  src={icon}
                  className="submenu-item-icon"
                  alt="submenu icon"
                />
                {alias}
              </>
            );

            return (
              <li
                className={cc(classNameMenuItem, {
                  active: id === activeProfession,
                })}
                key={id}
              >
                {professionShowStatus && pathname === PROFESSIONS_ROUTE ? (
                  <Scroll
                    className="submenu-mobile-scroll"
                    to={Navigation.CARD_DETAILS}
                    onClick={() => onSelect(String(id))}
                    offset={-150}
                    duration={300}
                    smooth
                  >
                    {content}
                  </Scroll>
                ) : (
                  <Link
                    to={PROFESSIONS_ROUTE}
                    onClick={() => onSelect(String(id))}
                    className="submenu-mobile-scroll"
                  >
                    {content}
                  </Link>
                )}
              </li>
            );
          },
        )}
      </ul>
    </div>
  );
};

export default HeaderSubNav;
