import { FC, useContext, useState } from 'react';
import Button from '../../form-controls/Button';
import { PromptsContext } from '../../../contexts/promptsContext';
import { ButtonType } from '../../../types/common';

export const ModalContract: FC = () => {
  const { restriction } = useContext(PromptsContext);

  const [isHide, setIsHide] = useState<boolean>(false);

  const hideMessage = () => {
    setIsHide(true);
  };

  const showMessage = () => {
    setIsHide(!isHide);
  };

  return (
    <div className={`modal-contract ${isHide ? 'is-down' : 'is-up'}`}>
      <div className="modal-wrapper">
        <Button
          className="modal-arrow"
          type={ButtonType.BUTTON}
          onClick={showMessage}
        >
          <span className="modal-arrow-icon" />
        </Button>
        <p className="modal-text">{restriction}</p>
        <Button
          className="button"
          type={ButtonType.BUTTON}
          onClick={hideMessage}
        >
          Зрозуміло
        </Button>
      </div>
    </div>
  );
};
