import { configureStore } from '@reduxjs/toolkit';
import propositionsReducer from './propositionsSlice';
import professionsReducer from './professionsSlice';
import footerReducer from './footerSlice';

const store = configureStore({
  reducer: {
    propositions: propositionsReducer,
    professions: professionsReducer,
    footer: footerReducer,
  },
});

export type RootStore = ReturnType<typeof store.getState>;

export default store;
