import { FC, MouseEventHandler } from 'react';
import Button from '../../form-controls/Button';
import { ButtonType } from '../../../types/common';

interface OfferEmptyProps {
  textContent?: string;
  buttonTitle?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const OfferEmpty: FC<OfferEmptyProps> = ({
  textContent,
  buttonTitle,
  onClick,
}) => (
  <div className="table-empty">
    {textContent}
    <div className="table-empty-button-holder">
      <Button
        type={ButtonType.BUTTON}
        className="button-link responsive"
        onClick={onClick}
      >
        {buttonTitle}
      </Button>
    </div>
  </div>
);
