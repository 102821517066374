import React, {
  FC, ReactNode, useEffect, useState,
} from 'react';
import { Online } from 'react-detect-offline';
import { useLocation, useNavigate } from 'react-router-dom';
import { ON_REJECTED } from '../../constants/events';
import { ButtonType } from '../../types/common';
import { CALCULATOR_DEFAULT_ROUTE } from '../../constants/routes';
import img from '../../assets/img/reload.svg';

interface ReloadWrapperProps {
  children: ReactNode;
}

const ReloadWrapper: FC<ReloadWrapperProps> = ({ children }) => {
  const [isError, setIsError] = useState<boolean>(false);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const setError = () => {
      setIsError(true);

      pathname.includes(CALCULATOR_DEFAULT_ROUTE)
        && navigate(CALCULATOR_DEFAULT_ROUTE);

      window.setTimeout(() => window.location.reload(), 30000);
    };

    window.addEventListener(ON_REJECTED, setError);

    return () => window.removeEventListener(ON_REJECTED, setError);
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  if (isError) {
    return (
      <Online>
        <div className="error full-screen">
          <div className="error-wrapper">
            <div className="error-holder">
              <div className="error-title">
                Контент, який Ви шукаєте, тимчасово недоступний
              </div>
              <div className="error-subtitle">
                Через технічні неполадки контент тимчасово недоступний для
                перегляду. Будь ласка, спробуйте перезавантажити сторінку.
              </div>
            </div>
            <img className="error-image" src={img} alt="" />
          </div>
          <div className="error-button-link">
            <button
              className="button-link full-width"
              type={ButtonType.BUTTON}
              onClick={reloadPage}
            >
              Перезавантажити сторінку
            </button>
          </div>
        </div>
      </Online>
    );
  }

  return children as any;
};

export default ReloadWrapper;
