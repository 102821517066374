import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PropositionsData } from '../types/common';

interface Speciality {
  code: string;
  title: string;
}

interface PropositionsState extends PropositionsData {
  favoritePropositionIds: number[];
  activeSpeciality: Speciality[];
}

const initialPropositionsState: PropositionsState = {
  budget: [],
  contract: [],
  favoritePropositionIds: [],
  activeSpeciality: [],
};

export const propositionsSlice = createSlice({
  name: 'propositions',
  initialState: initialPropositionsState,
  reducers: {
    setPropositionsData: (state, { payload }) => {
      state.budget = payload.budget;
      state.contract = payload.contract;
    },
    resetPropositionsData: (state) => {
      state.budget = [];
      state.contract = [];
    },
    addToFavorite: (state, { payload }: PayloadAction<number>) => {
      state.favoritePropositionIds = [...state.favoritePropositionIds, payload];
    },
    setActiveSpeciality: (state, { payload }) => {
      state.activeSpeciality = [...state.activeSpeciality, payload];
    },
    removeFromActiveSpeciality: (state, { payload }) => {
      state.activeSpeciality = state.activeSpeciality.filter(
        ({ code }) => code !== payload.code,
      );
    },
    clearActiveSpecialities: (state) => {
      state.activeSpeciality = [];
    },
    removeFromFavorite: (state, { payload }: PayloadAction<number>) => {
      state.favoritePropositionIds = state.favoritePropositionIds.filter(
        (id) => id !== payload,
      );
    },
  },
});

export const {
  setPropositionsData,
  resetPropositionsData,
  addToFavorite,
  setActiveSpeciality,
  removeFromActiveSpeciality,
  clearActiveSpecialities,
  removeFromFavorite,
} = propositionsSlice.actions;

export default propositionsSlice.reducer;
