import React, { FC } from 'react';
import letterImg from '../../../assets/img/letter-bg.svg';
import Button from '../../form-controls/Button';
import { ButtonType } from '../../../types/common';

interface LetterProps {
  letterTitle: string;
  letterDescription: string;
  letterLink?: string;
}

const img = <img src={letterImg} alt="Motivation letter" />;

export const Letter: FC<LetterProps> = ({
  letterTitle,
  letterDescription,
  letterLink,
}) => (
  <div className="calculator-info letter-holder">
    <div className="calculator-info-text">
      <div className="title-arrow-2" />
      <div className="calculator-info-title">{letterTitle}</div>
      <div className="calculator-info-picture mobile">{img}</div>
      <div className="calculator-info-subtitle">{letterDescription}</div>
      {letterLink ? (
        <a href={letterLink} target="_blank">
          <Button type={ButtonType.BUTTON} className="button-link responsive">
            Завантажити
          </Button>
        </a>
      ) : null}
    </div>
    <div className="calculator-info-picture desktop">{img}</div>
  </div>
);
