import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfessionMap } from '../types/professions';

interface InitialProfessionsState {
  professions: ProfessionMap[];
  activeProfessionId: number;
  professionShowStatus: boolean;
  isLoaded: boolean;
}

const initialProfessionsState: InitialProfessionsState = {
  professions: [
    {
      id: -1,
      image: '',
      isDisplay: false,
      nixLink: '',
      priority: -1,
      localization: {
        ua: {
          title: '',
          description: '',
          alias: '',
          icon: '',
        },
      },
      details: {
        image: '',
        softSkills: [
          {
            id: 0,
            localization: {
              ua: {
                name: '',
              },
            },
          },
        ],
        hardSkills: [
          {
            id: 0,
            localization: {
              ua: {
                name: '',
              },
            },
          },
        ],
        englishLevel: null,
        specialities: [
          {
            id: -1,
            code: 0,
            matchPercentage: 0,
            localization: {
              ua: {
                title: '',
              },
            },
          },
        ],
        localization: {
          ua: {
            title: '',
            description: '',
            responsibilities: [],
            result: '',
            condition: '',
          },
        },
      },
    },
  ],
  activeProfessionId: 4,
  professionShowStatus: false,
  isLoaded: false,
};

export const professionsSlice = createSlice({
  name: 'professions',
  initialState: initialProfessionsState,
  reducers: {
    setProfessions: (state, { payload }) => {
      state.professions = payload;
      state.isLoaded = true;
    },
    setActiveProfessionId: (state, { payload }: PayloadAction<number>) => {
      state.activeProfessionId = payload;
    },
    setProfessionShowStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.professionShowStatus = payload;
    },
  },
});

export const {
  setProfessions,
  setActiveProfessionId,
  setProfessionShowStatus,
} = professionsSlice.actions;

export default professionsSlice.reducer;
