import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FooterLinks } from '../../../constants/linksFooter';
import footerLogo from '../../../assets/img/footer-logo.svg';
import location from '../../../assets/img/location.svg';
import phoneImg from '../../../assets/img/phone.svg';
import mail from '../../../assets/img/mail.svg';
import {
  ABOUT_US_ROUTE,
  CALCULATOR_DEFAULT_ROUTE,
  PROFESSIONS_ROUTE,
  START_TEST_ROUTE,
  TEST_RESULT_ROUTE,
} from '../../../constants/routes';
import { RootStore } from '../../../store/store';
import { useInitialDataLoaded } from '../../../hooks/useInitialDataLoaded';

export const Footer: FC = () => {
  const { email, phone, socialMedia } = useSelector(
    ({ footer }: RootStore) => footer.footer,
  );

  const isInitialDataLoaded = useInitialDataLoaded();

  return isInitialDataLoaded ? (
    <footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-info">
          <div className="footer-logo-holder">
            <Link to={PROFESSIONS_ROUTE}>
              <img src={footerLogo} width={117} height={36} alt="logo" />
            </Link>
          </div>
          <p className="footer-text">
            Дізнайся шанси стати студентом омріяного вишу. Заповнюй форму з
            балами НМТ. Онлайн-калькулятор розрахує ймовірність вступу на основі
            статистики за попередній рік.
          </p>
          <span className="footer-support">
            Сайт створений компанією
            {' '}
            <a
              className="footer-support-link"
              href={FooterLinks.NIXSOLUTIONS}
              target="_blank"
            >
              NIX
            </a>
          </span>
        </div>
        <div className="footer-contacts">
          <ul className="footer-list">
            <li className="footer-item">
              <Link className="footer-menu-link" to={PROFESSIONS_ROUTE}>
                Професії
              </Link>
            </li>
            <li className="footer-item">
              <Link className="footer-menu-link" to={CALCULATOR_DEFAULT_ROUTE}>
                Калькулятор
              </Link>
            </li>
            <li className="footer-item">
              <Link
                className="footer-menu-link"
                to={
                  localStorage.testResult ? TEST_RESULT_ROUTE : START_TEST_ROUTE
                }
              >
                Тест
              </Link>
            </li>
            <li className="footer-item">
              <Link className="footer-menu-link" to={ABOUT_US_ROUTE}>
                Про нас
              </Link>
            </li>
          </ul>
          <div className="footer-contacts-wrapper">
            <div className="footer-contacts-title">Контакти</div>
            <div className="footer-contacts-item">
              <img
                className="location"
                src={location}
                width={24}
                height={24}
                alt="location"
              />
              <span className="footer-contacts-text">{FooterLinks.GEO}</span>
            </div>
            <div className="footer-contacts-item">
              <img
                className="location"
                src={phoneImg}
                width={24}
                height={24}
                alt="location"
              />
              <a className="footer-contacts-text" href={`tel:${phone}`}>
                {phone}
              </a>
            </div>
            <div className="footer-contacts-item">
              <img
                className="location"
                src={mail}
                width={24}
                height={24}
                alt="location"
              />
              <a className="footer-contacts-text" href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          </div>
          <div className="footer-social">
            <div className="footer-social-title">Доєднуйтесь до нас </div>
            <div className="social-wrapper">
              {socialMedia.map(({
                priority, link, logo, alias,
              }) => (
                <a
                  key={priority}
                  className="social-link"
                  href={link}
                  target="_blank"
                >
                  <img src={logo} width={48} height={48} alt={alias} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-rights">©2024 ITsWAY · All Right Reserved</div>
    </footer>
  ) : null;
};
