import React, { FC, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CalculatorPageDataProvider } from '../../contexts/calculatedPropositionContext';
import CalculatorPage from '../pages/CalculatorPage';
import HeaderNav from '../common/HeaderNav';
import Footer from '../common/Footer';
import ToTopButton from '../common/ToTopButton';
import ReloadWrapper from '../ReloadWrapper';
import {
  ABOUT_US_ROUTE,
  CALCULATOR_ROUTES,
  PROFESSIONS_ROUTE,
  START_TEST_ROUTE,
  TEST_RESULT_ROUTE,
  TEST_ROUTE,
} from '../../constants/routes';
import Preloader from '../common/Preloader';
import { ScrollToTop } from '../common/ScrollToTop/ScrollToTop';

const ProfessionsPage = lazy(() => import('../pages/ProfessionsPage'));
const StartTestPage = lazy(() => import('../pages/StartTestPage'));
const TestPage = lazy(() => import('../pages/TestPage'));
const ResultTestPage = lazy(() => import('../pages/ResultTestPage'));
const AboutUsPage = lazy(() => import('../pages/AboutUsPage'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

export const App: FC = () => (
  <ReloadWrapper>
    <ScrollToTop />
    <QueryClientProvider client={queryClient}>
      <CalculatorPageDataProvider>
        <HeaderNav />
        <div className="page-content">
          <Suspense fallback={<Preloader />}>
            <Routes>
              <Route path={PROFESSIONS_ROUTE} element={<ProfessionsPage />} />
              {CALCULATOR_ROUTES.map((route) => (
                <Route key={route} path={route} element={<CalculatorPage />} />
              ))}
              <Route path={START_TEST_ROUTE} element={<StartTestPage />} />
              <Route path={TEST_ROUTE} element={<TestPage />} />
              <Route path={TEST_RESULT_ROUTE} element={<ResultTestPage />} />
              <Route path={ABOUT_US_ROUTE} element={<AboutUsPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Suspense>
        </div>
        <Footer />
        <ToTopButton />
      </CalculatorPageDataProvider>
    </QueryClientProvider>
  </ReloadWrapper>
);
