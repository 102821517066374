import {
  FC, RefObject, useContext, useEffect, useRef, useState,
} from 'react';
import Button from '../../form-controls/Button';
import { FilterContext } from '../../../contexts/filterContext';
import {
  checkClickOutside,
  composeClasses as cc,
} from '../../../helpers/common';
import nixImg from '../../../assets/img/nix-logo.svg';
import closeImg from '../../../assets/img/close-1.svg';
import { useParentWidth } from '../../../hooks/useParentWidth';

interface FilterProps {
  universityFilterOptions: string[];
  filterParentRef?: RefObject<HTMLDivElement>;
}

export const Filter: FC<FilterProps> = ({
  universityFilterOptions,
  filterParentRef,
}) => {
  const {
    filters,
    toggleUniversityTitle,
    toggleSelectionsFilter,
    togglePartnersFilter,
  } = useContext(FilterContext);
  const { universitiesFilter, selectionsFilter, partnersFilter } = filters;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const filterRef = useRef<HTMLDivElement>(null);

  const filterParentWidth = useParentWidth(filterParentRef);

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const listener = (event: MouseEvent) => {
    if (checkClickOutside(filterRef, event)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', listener);
    }

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [isOpen]);

  return (
    <div
      className={cc('filter-wrapper', { 'has-parent': Boolean(filterRef) })}
      ref={filterRef}
    >
      <Button
        className={cc('button-filter', { 'is-focus': isOpen })}
        onClick={toggleIsOpen}
      />
      <div
        className={`filters ${!isOpen ? 'is-hide' : ''}`}
        style={{ width: filterParentWidth || undefined }}
      >
        <span className="filters-close" onClick={toggleIsOpen}>
          <img src={closeImg} alt="close" />
        </span>
        <span className="filter-title">Фільтрувати за...</span>
        <span className="filter-option">ЗВО</span>
        <div className="checkbox-list">
          {universityFilterOptions.map((option) => (
            <div key={option} className="checkbox-wrapper">
              <span className="checkbox-name">{option}</span>
              <label className="checkbox-label">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  checked={universitiesFilter.includes(option)}
                  onChange={toggleUniversityTitle.bind(null, option)}
                />
                <span className="checkbox-box" />
              </label>
            </div>
          ))}
        </div>
        <div className="filter-line" />
        <div className="filter-switch-list">
          <div className="filter-switch">
            <div className="filter-options">
              <span className="filter-option">За збереженими</span>
            </div>
            <div className="switch-wrapper">
              <label>
                <input
                  className="switch-input"
                  type="checkbox"
                  checked={selectionsFilter}
                  onChange={toggleSelectionsFilter}
                />
                <span className="switch-check" />
              </label>
            </div>
          </div>
          <div className="filter-switch">
            <div className="filter-options">
              <span className="filter-option">Є партнером</span>
              <img src={nixImg} alt="nix logotype" />
            </div>
            <div className="switch-wrapper">
              <label>
                <input
                  className="switch-input"
                  type="checkbox"
                  checked={partnersFilter}
                  onChange={togglePartnersFilter}
                />
                <span className="switch-check" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
