import React, { memo, MouseEventHandler, ReactNode } from 'react';
import { ButtonType } from '../../../types/common';

interface ButtonProps {
  type?: ButtonType;
  children?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const Button = memo<ButtonProps>(
  ({
    children,
    onClick,
    className = 'button',
    type = ButtonType.BUTTON,
    disabled = false,
  }) => (
    <button
      className={className}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  ),
);
