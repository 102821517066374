import { FC, useContext, useState } from 'react';
import { PropositionFullData } from '../../../types/common';
import TableSortForDesktop from '../TableSortForDesktop';
import { SortingContext } from '../../../contexts/sortingContext';
import { PromptsContext } from '../../../contexts/promptsContext';
import nixLogo from '../../../assets/img/nix-logo.svg';

interface TableHeaderProps {
  minScore?: PropositionFullData;
  calculatedScore?: PropositionFullData;
  isCalculatorPage?: boolean;
}

export const TableHeader: FC<TableHeaderProps> = ({
  minScore,
  calculatedScore,
  isCalculatorPage,
}) => {
  const { minScore: minScoreMessage } = useContext(PromptsContext);

  const { sort, setSpecialitySort, setScoreSort } = useContext(SortingContext);

  const [hovered, setHovered] = useState<boolean>(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  return (
    <thead className="table-head">
      <tr>
        <th className="table-col">Назва конкурсної пропозиції</th>
        <th className="table-col">Назва ЗВО</th>
        {!isCalculatorPage && (
          <>
            <th className="table-col"> </th>
            <th className="table-col"> </th>
          </>
        )}
        {setSpecialitySort && isCalculatorPage && (
          <th className="table-col">
            Спеціальність
            <TableSortForDesktop
              sortType={sort.speciality}
              setSort={setSpecialitySort}
            />
          </th>
        )}
        {(minScore || calculatedScore) && (
          <>
            {isCalculatorPage && <th className="table-col">Ваш бал</th>}
            <th className="table-col">
              Мінімальний бал
              <div className="button-question">
                <span
                  className="question-icon"
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              </div>
              <div
                className={
                  hovered
                    ? 'question-helper hovered'
                    : 'question-helper not-hovered'
                }
              >
                <span>{minScoreMessage}</span>
              </div>
              <TableSortForDesktop
                sortType={sort.score}
                setSort={setScoreSort}
              />
            </th>
          </>
        )}
        <th className="table-col">
          <div className="table-col-partner">
            Партнер
            <img src={nixLogo} width={41} height={22} alt="" />
          </div>
        </th>
      </tr>
    </thead>
  );
};
