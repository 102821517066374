import { FC, ReactNode } from 'react';

interface ValidationErrorsProps {
  errorContent?: ReactNode;
  errorId: string;
  errorClassName?: string;
}

export const ValidationErrors: FC<ValidationErrorsProps> = ({
  errorId,
  errorContent,
  errorClassName = '',
}) => (
  <div
    className={`subject-error is-invalid is-empty ${errorClassName}`}
    data-testid={errorId}
  >
    {errorContent}
  </div>
);
