import React, {
  FC,
  MouseEventHandler,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import TableHeader from '../TableHeader';
import Button from '../../form-controls/Button';
import TableItems from '../TableItems';
import OfferEmpty from '../OfferEmpty';
import TableCard from '../TableCard';
import { SortingContext } from '../../../contexts/sortingContext';
import { FilterContext } from '../../../contexts/filterContext';
import { ButtonType, PropositionFullData } from '../../../types/common';
import { RootStore } from '../../../store/store';
import { useFavorite } from '../../../hooks/useFavorite';

interface TableProps {
  unchangingPropositions: PropositionFullData[];
  propositions: PropositionFullData[];
  offerButtonTitle: string;
  offerTextContent: string;
  minScore: PropositionFullData | undefined;
  calculatedScore: PropositionFullData | undefined;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onClickProposition: (proposition: PropositionFullData) => void;
}

export const Table: FC<TableProps> = ({
  unchangingPropositions,
  propositions,
  offerButtonTitle,
  offerTextContent,
  minScore,
  calculatedScore,
  onClick,
  onClickProposition,
}) => {
  const { favoritePropositionIds, toggleFavoriteProposition } = useFavorite();
  const { sort } = useContext(SortingContext);
  const { filters } = useContext(FilterContext);
  const [visiblePropositions, setVisiblePropositions] = useState<
    PropositionFullData[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [balanceCount, setBalanceCount] = useState<number>(0);
  const { budget, contract } = useSelector(
    ({ propositions: prop }: RootStore) => prop,
  );

  const showMore = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    setBalanceCount(propositions.length - visiblePropositions.length);
  }, [visiblePropositions]);

  useEffect(() => {
    setCurrentPage(1);
  }, [budget, contract, filters, sort]);

  useEffect(() => {
    setVisiblePropositions(
      propositions.filter((_, index) => index + 1 <= currentPage * 18),
    );
  }, [currentPage, propositions]);

  const emptyState = useMemo(() => {
    if (unchangingPropositions.length === 0) {
      return (
        <OfferEmpty
          onClick={onClick}
          buttonTitle={offerButtonTitle}
          textContent={offerTextContent}
        />
      );
    }

    if (unchangingPropositions.length !== 0 && propositions.length === 0) {
      return (
        <div className="table-empty">
          На жаль, за даним пошуком нічого не знайдено!
        </div>
      );
    }

    return null;
    // eslint-disable-next-line max-len
  }, [
    unchangingPropositions,
    onClick,
    offerButtonTitle,
    offerTextContent,
    unchangingPropositions,
    propositions,
  ]);

  return (
    <>
      <div id="propositions-table-wrapper">
        <div className="table-wrapper">
          <table className="table">
            <TableHeader
              minScore={minScore}
              calculatedScore={calculatedScore}
              isCalculatorPage
            />
            {!visiblePropositions.length ? null : (
              <tbody className="table-body">
                {propositions
                  && visiblePropositions.map((proposition) => {
                    const {
                      id,
                      localization,
                      nixPartnership,
                      department,
                      enrolleeScore,
                      passScore,
                      speciality,
                    } = proposition;

                    return (
                      <TableItems
                        key={id}
                        id={id}
                        ua={localization.ua}
                        nixPartnership={nixPartnership}
                        department={department}
                        enrolleeScore={enrolleeScore}
                        passScore={passScore}
                        speciality={speciality}
                        isActive={favoritePropositionIds.includes(id)}
                        onClick={() => onClickProposition(proposition)}
                        onClickStar={() => toggleFavoriteProposition(id)}
                      />
                    );
                  })}
              </tbody>
            )}
          </table>
          {!visiblePropositions.length ? emptyState : null}
        </div>
        {balanceCount !== 0 && (
          <div className="table-more">
            <Button
              className="button-more"
              type={ButtonType.BUTTON}
              onClick={showMore}
            >
              Показати більше (
              {balanceCount}
              )
            </Button>
          </div>
        )}
      </div>
      <div className="mobile-table-cards">
        {!visiblePropositions.length ? (
          emptyState
        ) : (
          <>
            <div className="mobile-table-cards-list">
              {propositions
                && visiblePropositions.map((proposition) => {
                  const {
                    id,
                    localization,
                    nixPartnership,
                    speciality,
                    department,
                    passScore,
                    enrolleeScore,
                  } = proposition;

                  return (
                    <TableCard
                      key={id}
                      id={id}
                      ua={localization.ua}
                      nixPartnership={nixPartnership}
                      department={department}
                      enrolleeScore={enrolleeScore}
                      passScore={passScore}
                      speciality={speciality}
                      isActive={favoritePropositionIds.includes(id)}
                      onClick={() => onClickProposition(proposition)}
                      onClickStar={() => toggleFavoriteProposition(id)}
                    />
                  );
                })}
            </div>
            {balanceCount !== 0 && (
              <div className="table-more">
                <Button
                  className="button-more"
                  type={ButtonType.BUTTON}
                  onClick={showMore}
                >
                  Показати більше (
                  {balanceCount}
                  )
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
