import React, { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Input from '../Input';
import Button from '../Button';
import CourseSelect from '../CourseSelect';
import { SpecialitySelect } from '../SpecialitySelect/SpecialitySelect';
import { setPropositionsData } from '../../../store/propositionsSlice';
import { postScores } from '../../../api/calculatorsPage';
import { schoolGraduateCalculateValidationScheme } from '../../../schemas/calculateSchemas';
import { ON_REJECTED } from '../../../constants/events';
import { useHash } from '../../../hooks/useHash';
import { CALCULATOR_DEFAULT_ROUTE } from '../../../constants/routes';
import {
  ButtonType,
  InputType,
  SchoolFormContent,
  SelectCourses,
  SubjectResponse,
} from '../../../types/common';

interface SchoolTabProps {
  active: boolean;
  subjects: SubjectResponse[];
  courses: SelectCourses[];
}

enum HeaderTabulation {
  SCHOOL = 'school-graduate',
  COLLEGE = 'college-graduate',
}

const SchoolTab: FC<SchoolTabProps> = ({
  subjects,
  courses,
  active = true,
}) => {
  const initialValues: SchoolFormContent = {
    ukr: '',
    mat: '',
    his: '',
    subject: {
      name: '',
      grade: '',
    },
    course: {
      name: '',
      grade: '',
    },
    status: false,
  };

  const nav = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { initHash } = useHash();

  const handleSubmit = (formContent: SchoolFormContent) => {
    const schoolRequest = {
      ...formContent,
      subject: {
        [formContent.subject.name]: formContent.subject.grade,
      },
      course: formContent.course?.name as string,
    };

    setIsLoading(true);

    nav(`..${CALCULATOR_DEFAULT_ROUTE}/${HeaderTabulation.COLLEGE}`, {
      replace: true,
    });

    postScores(schoolRequest)
      .then((response) => {
        dispatch(setPropositionsData(response));
        setIsLoading(false);
      })
      .catch(() => {
        window.dispatchEvent(new Event(ON_REJECTED));
      });

    initHash();
  };

  return (
    <div
      id="school-graduate-tab-content"
      className={`tabs-content ${active ? 'is-active' : ''}`}
    >
      <Formik
        validationSchema={schoolGraduateCalculateValidationScheme}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors }) => (
          <Form noValidate>
            <div className="form-fields">
              <div className="fields-group">
                {subjects
                  && subjects
                    .slice(0, 3)
                    .map(({
                      id, alias, localization, level,
                    }) => (
                      <div key={id} className="subjects">
                        <Input
                          fieldName={alias}
                          type={InputType.NUMBER}
                          labelValue={localization.ua.title}
                        />
                      </div>
                    ))}
              </div>
              <div className="subject-select-control">
                <SpecialitySelect
                  selectItems={subjects.slice(3)}
                  selectName="subject.name"
                  selectTitle="Предмет за вибором"
                  selectedValue={String(values.subject.name)}
                  onChange={(event) => {
                    setFieldValue('subject.name', event.target.value);
                  }}
                />
                <Input
                  fieldName="subject.grade"
                  type={InputType.NUMBER}
                  isSelect
                />
              </div>
              <CourseSelect
                selectName="course.name"
                selectTitle="Успішне закінчення підготовчих курсів (сертифікат)"
                selectItems={courses}
                selectedValue={String(values.course.name)}
                onChange={(event) => {
                  setFieldValue('course.name', event.target.value);
                }}
              />
            </div>
            <Button
              type={ButtonType.SUBMIT}
              className="button-link"
              disabled={isLoading}
            >
              Розрахувати
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SchoolTab;
