import { RefObject, useEffect, useState } from 'react';

export const useParentWidth = (parentRef?: RefObject<HTMLDivElement>) => {
  const [parentWidth, setParentWidth] = useState<number | undefined>();

  useEffect(() => {
    const onWindowResize = () => {
      const parentEl = parentRef?.current;

      if (parentEl) {
        setParentWidth(Math.ceil(parentEl.offsetWidth));
      }
    };

    window.addEventListener('resize', onWindowResize);

    onWindowResize();

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return parentWidth;
};
