import React, { memo } from 'react';

interface TitleProps {
  title: string;
}

const ProfessionsPageTitle = memo<TitleProps>(({ title }) => (
  <h1 className="title with-subtitle">
    {title}
    {' '}
    <span className="title title-bold" />
  </h1>
));

export default ProfessionsPageTitle;
