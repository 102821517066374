import axiosInstance from './axios';
import { CALCULATORS_PAGE_URL, CALCULATOR_URL } from '../constants/urls';
import {
  ResponsePropositions,
  MainPageResponseData,
  SchoolFormContent,
  CollegeFormContent,
} from '../types/common';

export const getAllContent = (): Promise<MainPageResponseData> =>
  axiosInstance.get(CALCULATORS_PAGE_URL);

interface PostSchoolFormContent extends Omit<SchoolFormContent, 'course'> {
  course: string | null;
}

export const postScores = ({
  ukr,
  mat,
  his,
  subject,
  course,
  status,
}: PostSchoolFormContent): Promise<ResponsePropositions> =>
  axiosInstance.post(CALCULATOR_URL, {
    data: {
      subjects: {
        s1: {
          ukr,
        },
        s2: {
          mat,
        },
        s3: {
          his,
        },
        s4: subject,
      },
      course,
    },
    status,
  });

export const postSpeciality = ({
  ukr,
  mat,
  subject,
  status,
}: CollegeFormContent): Promise<ResponsePropositions> =>
  axiosInstance.post(CALCULATOR_URL, {
    data: {
      subjects: {
        s1: {
          ukr,
        },
        s2: {
          mat,
        },
        s3: subject,
      },
    },
    status,
  });
