import React, {
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { composeClasses as cc } from '../../../helpers/common';
import Button from '../../form-controls/Button';

interface ExpandableProps {
  children: ReactNode;
}

export const Expandable: FC<ExpandableProps> = ({ children }) => {
  const [isOpen, setOpen] = useState(false);

  const holderRef = useRef<HTMLDivElement>(null);

  const toggleOpen = useCallback(() => {
    setOpen((prevState) => {
      const nextState = !prevState;

      if (!nextState) {
        holderRef.current?.scrollIntoView();
      }

      return nextState;
    });
  }, []);

  const buttonText = useMemo(
    () => (isOpen ? 'сховати' : 'Показати більше'),
    [isOpen],
  );

  return (
    <div ref={holderRef} className={cc('expandable', { 'is-open': isOpen })}>
      <div className="expandable-content">{children}</div>
      <div className="expandable-button">
        <Button
          className={cc('button-more button-expandable', { expanded: isOpen })}
          onClick={toggleOpen}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
