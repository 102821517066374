import * as Yup from 'yup';
import {
  getRequiredMessage,
  getObligatoryMessage,
  getSelectSubjectMessage,
  getMinGradeSubjectMessage,
  getMaxGradeSubjectMessage,
} from '../helpers/validationHelpers';

const scoreValidation = Yup.number()
  .min(100, getMinGradeSubjectMessage)
  .max(200, getMaxGradeSubjectMessage);

export const schoolGraduateCalculateValidationScheme = Yup.object({
  ukr: scoreValidation.required(getRequiredMessage),
  mat: scoreValidation.required(getRequiredMessage),
  his: scoreValidation.required(getRequiredMessage),
  subject: Yup.object().shape({
    name: Yup.string().required(getSelectSubjectMessage),
    grade: scoreValidation.required(getRequiredMessage),
  }),
  course: Yup.object().shape({
    name: Yup.string().nullable(),
  }),
});

export const collegeGraduateCalculateValidationScheme = Yup.object({
  speciality: Yup.string().required(getObligatoryMessage),
});
