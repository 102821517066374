import { RefObject } from 'react';

/**
 * Composes classes with condition instead of ternary operator
 * @param requiredClasses
 * @param optionalClasses
 */
export const composeClasses = (
  requiredClasses: string,
  optionalClasses: Record<string, boolean>,
): string =>
  Object.entries(optionalClasses).reduce(
    (previousValue, [className, isShown]) =>
      previousValue + (isShown ? ` ${className.trim()}` : ''),
    requiredClasses,
  );

export const formatPhoneNumber = (phone: string) => {
  const phoneOperator = phone.substring(0, 3);
  const phoneWithoutOperator = phone.substring(3);

  return `+38 (${phoneOperator}) ${phoneWithoutOperator.substring(
    0,
    3,
  )}-${phoneWithoutOperator.substring(3, 5)}-${phoneWithoutOperator.substring(
    5,
    7,
  )}`;
};

export const generateHash = (): string => {
  let result = '';
  const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < 32; i += 1) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * CHARACTERS.length));
  }

  return result;
};

export const checkClickOutside = <T extends HTMLElement>(
  parent: RefObject<T>,
  event: MouseEvent,
) => !parent.current?.contains(event.target as Node);

export const range = (startAt: number, arrayLength: number) =>
  Array.from(Array(arrayLength).keys()).map((i) => i + startAt);

export const findDuplicates = (array: number[]): number[] => {
  const counter: Record<number, number> = {};

  array.forEach((name) =>
    (counter[name] ? counter[name]++ : (counter[name] = 1)));

  return Object.values(counter);
};
