import {
  FC, useContext, useEffect, useState, MouseEvent, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Tab from '../form-controls/Tab';
import Table from '../common/Table';
import Search from '../form-controls/Search';
import ModalContract from '../common/ModalContract';
import { RootStore } from '../../store/store';
import { reportAction } from '../../api/actions';
import { useHash } from '../../hooks/useHash';
import { FilterContext } from '../../contexts/filterContext';
import { SortingContext } from '../../contexts/sortingContext';
import { SelectedProgramContext } from '../../contexts/selectedProgramContext';
import {
  Action,
  LayoutTabulation,
  PropositionFullData,
  SortType,
} from '../../types/common';
import { ON_REJECTED } from '../../constants/events';
import Filter from '../common/Filter';
import TableSortForMobile from '../common/TableSortForMobile';

interface LayoutTabsProps {
  issetMinScore: PropositionFullData | undefined;
  universities: string[];
}

export const LayoutTabs: FC<LayoutTabsProps> = ({
  issetMinScore,
  universities,
}) => {
  const { sort } = useContext(SortingContext);
  const { filters } = useContext(FilterContext);
  const { setSelectedProgram } = useContext(SelectedProgramContext);
  const [activeTab, setActiveTab] = useState(LayoutTabulation.BUDGET);
  const [searchValue, setSearchValue] = useState('');
  const [filteredBudget, setFilteredBudget] = useState<PropositionFullData[]>(
    [],
  );
  const [filteredContract, setFilteredContract] = useState<
    PropositionFullData[]
  >([]);
  const [foundBudgetPropositions, setFoundBudgetPropositions] = useState<
    PropositionFullData[]
  >([]);
  const [foundContractPropositions, setFoundContractPropositions] = useState<
    PropositionFullData[]
  >([]);
  const [sortedBudgetProposition, setSortedBudgetProposition] = useState<
    PropositionFullData[]
  >([]);
  const [sortedContractProposition, setSortedContractProposition] = useState<
    PropositionFullData[]
  >([]);

  const { budget, contract, favoritePropositionIds } = useSelector(
    (state: RootStore) => state.propositions,
  );
  const nav = useNavigate();

  const { pathname } = useLocation();

  const { getUserHash } = useHash();

  const budgetLinkTitle = `Бюджет (${foundBudgetPropositions.length})`;
  const contractLinkTitle = `Контракт (${foundContractPropositions.length})`;

  useEffect(() => {
    const { selectionsFilter, partnersFilter } = filters;
    let tempFilteredBudget: PropositionFullData[] = [...budget];
    let tempFilteredContract: PropositionFullData[] = [...contract];

    tempFilteredBudget = filterByUniversities(tempFilteredBudget);
    tempFilteredContract = filterByUniversities(tempFilteredContract);

    if (selectionsFilter) {
      tempFilteredBudget = filterByFavorites(tempFilteredBudget);
      tempFilteredContract = filterByFavorites(tempFilteredContract);
    }

    if (partnersFilter) {
      tempFilteredBudget = filterByPartners(tempFilteredBudget);
      tempFilteredContract = filterByPartners(tempFilteredContract);
    }

    setFilteredBudget(tempFilteredBudget);
    setFilteredContract(tempFilteredContract);
  }, [filters, searchValue, favoritePropositionIds, budget, contract]);

  useEffect(() => {
    findPropositions(
      filteredBudget,
      LayoutTabulation.BUDGET,
      setFoundBudgetPropositions,
    );
    findPropositions(
      filteredContract,
      LayoutTabulation.CONTRACT,
      setFoundContractPropositions,
    );
  }, [searchValue, activeTab, filteredBudget, filteredContract]);

  useEffect(() => {
    const sortedBudget = sortSpeciality(
      sortScore(foundBudgetPropositions, sort.score),
      sort.speciality,
    );
    const sortedContract = sortSpeciality(
      sortScore(foundContractPropositions, sort.score),
      sort.speciality,
    );

    setSortedBudgetProposition(sortedBudget);
    setSortedContractProposition(sortedContract);
  }, [activeTab, sort, foundBudgetPropositions, foundContractPropositions]);

  useEffect(() => {
    setSearchValue('');
  }, [activeTab]);

  useEffect(() => {
    if (contract.length === 0) {
      setActiveTab(LayoutTabulation.BUDGET);
    }
  }, [budget, contract]);

  const findPropositions = (
    proposition: PropositionFullData[],
    propositionName: string,
    setFoundPropositions: (value: PropositionFullData[]) => void,
  ) => {
    if (activeTab === propositionName && searchValue) {
      const filteredPropositions = proposition.filter(
        ({ speciality, localization, department }) =>
          [
            speciality.localization.ua.title.toLowerCase(),
            speciality.code,
            speciality.localization.ua.alias.toLowerCase(),
            localization.ua.description.toLowerCase(),
            localization.ua.title.toLowerCase(),
            department.university.localization.ua.title.toLowerCase(),
          ].find((item) =>
            new RegExp(`.*${searchValue.toLowerCase()}.*`).test(item)),
      );

      setFoundPropositions(filteredPropositions);
    } else {
      setFoundPropositions(proposition);
    }
  };

  const sortScore = (
    propositions: PropositionFullData[],
    sortType: SortType | null,
  ) =>
    [...propositions].sort(
      ({ passScore: passScoreCurrent }, { passScore: passScoreNext }) => {
        if (sortType === SortType.ASC) {
          return Number(passScoreCurrent) - Number(passScoreNext);
        }
        if (sortType === SortType.DESC) {
          return Number(passScoreNext) - Number(passScoreCurrent);
        }

        return 0;
      },
    );

  const sortSpeciality = (
    propositions: PropositionFullData[],
    sortType: SortType | null,
  ) =>
    [...propositions].sort(
      (
        { speciality: { code: codeCurrent } },
        { speciality: { code: codeNext } },
      ) => {
        if (sortType === SortType.ASC) {
          return Number(codeCurrent) - Number(codeNext);
        }
        if (sortType === SortType.DESC) {
          return Number(codeNext) - Number(codeCurrent);
        }

        return 0;
      },
    );

  const getFirstPassScore = (propositions: PropositionFullData[]) =>
    propositions.find(({ passScore }) => passScore);

  const getFirstEnrolleScore = (propositions: PropositionFullData[]) =>
    propositions.find(({ enrolleeScore }) => enrolleeScore);

  const filterByUniversities = (propositions: PropositionFullData[]) => {
    const { universitiesFilter } = filters;

    return propositions.filter((proposition) =>
      universitiesFilter.includes(
        proposition.department.university.localization.ua.title,
      ));
  };

  const filterByPartners = (propositions: PropositionFullData[]) =>
    propositions.filter((proposition) => proposition.nixPartnership);

  const filterByFavorites = (propositions: PropositionFullData[]) =>
    propositions.filter(({ id }) => favoritePropositionIds.includes(id));

  const toggleToBudget = (event: MouseEvent) => {
    event.preventDefault();

    if (
      pathname.includes(LayoutTabulation.CONTRACT)
      || pathname.includes(LayoutTabulation.BUDGET)
    ) {
      nav(pathname.replace(LayoutTabulation.CONTRACT, LayoutTabulation.BUDGET));
    } else {
      nav(`${pathname}/${LayoutTabulation.BUDGET}`);
    }

    setActiveTab(LayoutTabulation.BUDGET);
  };

  const toggleToContract = (event: MouseEvent) => {
    event.preventDefault();

    if (
      pathname.includes(LayoutTabulation.CONTRACT)
      || pathname.includes(LayoutTabulation.BUDGET)
    ) {
      nav(pathname.replace(LayoutTabulation.BUDGET, LayoutTabulation.CONTRACT));
    } else {
      nav(`${pathname}/${LayoutTabulation.CONTRACT}`);
    }

    setActiveTab(LayoutTabulation.CONTRACT);
  };

  const onClickProposition = (proposition: PropositionFullData) => {
    const { id } = proposition;

    reportAction({
      propositionId: id,
      hash: getUserHash(),
      action: Action.LOOKED,
    }).catch(() => {
      window.dispatchEvent(new Event(ON_REJECTED));
    });

    if (
      pathname.includes(LayoutTabulation.CONTRACT)
      || pathname.includes(LayoutTabulation.BUDGET)
    ) {
      nav(`..${pathname}/prop/${id}`, { replace: true });
    } else {
      nav(`${pathname}/${activeTab}`);
      nav(`${pathname}/${activeTab}/prop/${id}`);
    }
    setSelectedProgram(proposition);
  };

  const filterParentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="layout-tabs-header">
        <div className="tabs">
          <Tab
            className={
              activeTab === LayoutTabulation.BUDGET
                ? 'tabs-item active'
                : 'tabs-item'
            }
            linkTitle={budgetLinkTitle}
            href={LayoutTabulation.BUDGET}
            onClick={toggleToBudget}
          />
          <Tab
            className={
              activeTab === LayoutTabulation.CONTRACT
                ? 'tabs-item active'
                : 'tabs-item'
            }
            linkTitle={contractLinkTitle}
            href={LayoutTabulation.CONTRACT}
            onClick={toggleToContract}
          />
        </div>
        <div ref={filterParentRef} className="filters-holder">
          <Search key={activeTab} onSubmit={setSearchValue} />
          <Filter
            universityFilterOptions={universities}
            filterParentRef={filterParentRef}
          />
          <TableSortForMobile
            filterParentRef={filterParentRef}
            issetMinScore={issetMinScore}
          />
        </div>
      </div>
      {activeTab === LayoutTabulation.BUDGET ? (
        <Table
          unchangingPropositions={budget}
          propositions={sortedBudgetProposition}
          offerButtonTitle="Переглянути контрактні пропозиції"
          offerTextContent="На жаль, за твоїм конкурсним балом відсутні бюджетні пропозиціїї"
          onClick={toggleToContract}
          onClickProposition={onClickProposition}
          calculatedScore={getFirstEnrolleScore(sortedBudgetProposition)}
          minScore={getFirstPassScore(sortedBudgetProposition)}
        />
      ) : (
        <>
          <Table
            unchangingPropositions={contract}
            propositions={sortedContractProposition}
            offerButtonTitle="Переглянути бюджетні пропозиції"
            offerTextContent="Вітаємо! За твоїм конкурсним балом доступні усі бюджетні пропозиції"
            onClick={toggleToBudget}
            onClickProposition={onClickProposition}
            calculatedScore={getFirstEnrolleScore(sortedContractProposition)}
            minScore={getFirstPassScore(sortedContractProposition)}
          />
          <ModalContract />
        </>
      )}
    </>
  );
};
