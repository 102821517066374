import React, { FC, useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';
import { ButtonType } from '../../../types/common';

const ToTopButton: FC = () => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 100 ? setIsDisplayed(true) : setIsDisplayed(false);
    });
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop({
      duration: 300,
      smooth: 'easeInOutQuint',
    });
  };

  return (
    <div>
      {isDisplayed && (
        <button
          type={ButtonType.BUTTON}
          className="button-scroll"
          onClick={scrollToTop}
        >
          <span className="button-scroll-arrow" />
        </button>
      )}
    </div>
  );
};

export default ToTopButton;
