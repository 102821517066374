import {
  FC, RefObject, useContext, useState,
} from 'react';
import SortMenu from '../SortMenu';
import { PropositionFullData, SortType } from '../../../types/common';
import { composeClasses as cc } from '../../../helpers/common';
import { SortingContext } from '../../../contexts/sortingContext';
import { useParentWidth } from '../../../hooks/useParentWidth';

interface TableSortForMobileProps {
  issetMinScore: PropositionFullData | undefined;
  filterParentRef?: RefObject<HTMLDivElement>;
}

export const TableSortForMobile: FC<TableSortForMobileProps> = ({
  issetMinScore,
  filterParentRef,
}) => {
  const { sort } = useContext(SortingContext);

  const [isShowed, setIsShowed] = useState<boolean>(false);

  const [isIncreaseSortOptions, setIsSortIncrease] = useState<boolean>(false);

  const filterParentWidth = useParentWidth(filterParentRef);

  const showSortIncrease = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();

    if (isIncreaseSortOptions && isShowed) {
      setIsShowed(false);
    } else {
      setIsShowed(true);
      setIsSortIncrease(true);
    }
  };

  const showSortDecrease = (event: { stopPropagation: () => void }) => {
    event.stopPropagation();

    if (!isIncreaseSortOptions && isShowed) {
      setIsShowed(false);
    } else {
      setIsShowed(true);
      setIsSortIncrease(false);
    }
  };

  return (
    <>
      <div className={cc('table-sort-mobile', { 'is-focus': isShowed })}>
        <div
          className={cc('table-sort-mobile-control is-up', {
            'is-active':
              sort.score === SortType.ASC || sort.speciality === SortType.ASC,
          })}
          onClick={showSortIncrease}
        />
        <div
          className={cc('table-sort-mobile-control is-down', {
            'is-active':
              sort.score === SortType.DESC || sort.speciality === SortType.DESC,
          })}
          onClick={showSortDecrease}
        />
      </div>
      {isShowed && (
        <SortMenu
          filterParentWidth={filterParentWidth}
          setIsShowed={setIsShowed}
          issetMinScore={issetMinScore}
          isIncreaseSortOptions={isIncreaseSortOptions}
        />
      )}
    </>
  );
};
