import axiosInstance from './axios';
import {
  PROFESSION_MAP_URL,
  PROFESSION_PROFESSIONS_URL,
} from '../constants/urls';
import { ProfessionMap } from '../types/professions';
import { PropositionFullData } from '../types/common';

export const getProfessionMap = (): Promise<ProfessionMap[]> =>
  axiosInstance.get(PROFESSION_MAP_URL);

export const getProfessionPropositions = (): Promise<PropositionFullData[]> =>
  axiosInstance.get(PROFESSION_PROFESSIONS_URL);
